import React, { useEffect } from "react";

function Chat({setOpenMenu}) {

  useEffect(() => {  
    setOpenMenu(false)
    var Tawk_API=Tawk_API||{};
    var Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/5f5dcacf4704467e89ee817a/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
    }, [])
      

  return (
    <div className="chat">
    </div>
  );
}

export default Chat;
