import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action';
import showAlert from '../server/ShowAlert';
import { setPreload } from '../server/CartsOpen';

export const customerList = () => {
    return dispatch => {
        axios.get(`${url}CustomerList`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }).then(x => {
            if (x.data) {
                dispatch({ type: actionType.SET_SHOP_LIST, payload: x.data })
            }
            else{
                showAlert({msgTitle:"הייתה בעיה  בשליפת הרשימה", msgType: "error"})
            }
        })
    }
}

export const customerListClear = () => {
    return dispatch => {
                dispatch({ type: actionType.SET_SHOP_LIST, payload: [] })
    }
}

export const addNewList = ( Name, customer_list_product ) => {
    const val = {
        'NAME': Name,
        'customer_list_product': customer_list_product?customer_list_product:[],
    }
    return dispatch => {
        dispatch(setPreload(true));
        axios.post(`${url}CustomerList/AddNewList`,val, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            }
        }).then(x => {
            if (x.data) {
                dispatch({ type: actionType.ADD_NEW_SHOP_LIST, payload: x.data })
                showAlert({msgTitle:" הרשימה נוספה בהצלחה", msgType: "success"})
            }
            else{
                showAlert({msgTitle:"הייתה בעיה בהוספת הרשימה",msgType: "error"})
            }
        })
        .finally(() => dispatch(setPreload(false)));
    }
}

export const customerListId = ( id) => {
    return axios.get(`${url}CustomerList?CustomerListId=${id}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}
export const addToCard = ( id,stationId) => {
    return axios.get(`${url}CustomerList/setCollectSite?CustomerListId=${id}&stationId=${stationId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}

export const addProductToList = (data) => {
    return axios.post(`${url}CustomerList/addProduct`,
        data,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
    }