import React, { useEffect, useState } from 'react'
import Select from "../../containers/Select";
import Input from "../../containers/Input";
import LocationRow from './LoactionRow';
import { getRows, saveColm } from '../../../server/Station';
import AddPlace from './AddPlace';
import showAlert from '../../../server/ShowAlert';
import { useSelector } from 'react-redux';

const LocationColumns = ({ columns, getRowsP, columnsId, getCellP, selectStation }) => {

    const [Active, setActive] = useState(columns?.Active)
    const [TemperTypeId, setTemperTypeId] = useState(columns?.TemperTypeId)
    const [rowsId, SetRowsId] = useState([]);
    const [addNew, setAddNew] = useState("");

    const { status_location, location_type } = useSelector(state => state.parameters)

    useEffect(() => {
        if (addNew === "rows") {
            getRow()
        }
    }, [addNew]);

    const GetCell = (rowsId, data) => {
        SetRowsId(rowsId);
        getCellP(rowsId, data, columns.Id)
    }

    const getRow = () => {
        // if (!columns.Rows) {
        getRows(columns.Id)
            .then(x => getRowsP(columns.Id, x.data))
            .catch(x => { })
        // }
    }
    const submit = () => {
        const data = {
            ...columns,
            Active,
            TemperTypeId
        }
        saveColm(data)
            .then(x => {
                x ? showAlert({ msgTitle: 'נתוני הטור נשמרו בהצלחה', msgText: '', msgType: 'success' }) :
                    showAlert({ msgTitle: "היה בעיה בשמירת הנתונים", msgText: '' })
            })
            .catch(x => { })
    }


    return (
        <div className='location-column' >
            <Input Value={columns.Name} Type="text" Title="שם טור" Disabled={true} />
            <Select Value={TemperTypeId} Options={location_type} Title="טמפרטורה" SelectChosen={(value) => setTemperTypeId(value)} />
            <Select Value={Active} Options={status_location} Title="סטטוס תא" SelectChosen={(value) => setActive(value)} />
            <div className='btn-save-change'>
                <button onClick={getRow}>הצג קומות</button>
                <button onClick={submit}> שמור שינוי</button>
            </div>
            <AddPlace LocationColumnId={columns.Id} selectStation={selectStation} setAddNew={setAddNew} />
            <div className='floors'>
                {columnsId === columns.Id && columns.Rows ? columns.Rows.map(x => <LocationRow key={x.Id}
                    selectStation={selectStation} rowsId={rowsId} row={x} getCellP={GetCell}
                />) : null}
            </div>
        </div>)

}

export default LocationColumns
