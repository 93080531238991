import React, { useState, useEffect, Fragment } from 'react';
import { getAllAdvertising } from '../../../server/Advertising';

import { globalFileServer } from '../../../../axiosInstance'
import Advertising from './Advertising';

const AdvertisingAll = () => {
    const [arr, setArr] = useState([])
    const [selected, setSelect] = useState(null);
    const [AddNew, setAddNew] = useState(false)

    useEffect(() => {
        getAllAdvertising()
            .then(x => setArr(x.data))
    }, [])

    const saveChange = (data) => {
        const arrDup = [...arr];
        const index = arrDup.findIndex(x => x.Id === data.Id)
        if (index >= 0) {
            arrDup[index] = data;
            setSelect(null);
        }
        else {
            arrDup.push(data);
            setAddNew(false)
            setSelect(data.Id)
        }
        setArr(arrDup)
    }
    const saveImg = ({ Id, PathUrl }) => {
        const arrDup = JSON.parse(JSON.stringify(arr));
        const index = arrDup.findIndex(x => x.Id === Id)
        if (index >= 0) {
            arrDup[index].PathUrl = PathUrl;
            setArr(arrDup)
        }

    }
    return (
        <Fragment>
            <h1 className='title-role'>פרסומות</h1>
            {AddNew ? <Advertising saveChange={saveChange} /> : <button className='btn' onClick={() => setAddNew(true)}>הוספת חדש</button>}
            {arr.map(x =>

                selected === x.Id ?
                    <Advertising advertisingData={x} key={x.Id} saveChange={saveChange} sevImg={saveImg} /> :
                    <div className='advertising' key={x.Id}>
                        <div className='ads-detail'>
                            {x.Id}
                            <div>{x.CategoryName}</div>
                            <div>{x.Url}</div>
                            <div>{x.ToDate}</div>
                            <button className='btn' onClick={() => setSelect(x.Id)}> לעריכה</button>
                        </div>
                        <img loading="lazy" className="main-img" src={globalFileServer + "Advertising/" + x.PathUrl} />
                    </div>)}

        </Fragment>
    )
}

export default AdvertisingAll
