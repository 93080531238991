import axios from 'axios';
import { url } from '../../axiosInstance';

export const GetPriceList = (Mkt,Barcode,stationId) => {
    return axios.get(`${url}GetPriceList?mkt=${Mkt}&barcode=${Barcode}&stationId=${stationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}