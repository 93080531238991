import * as actionType from './action'

const initialState = {
    categories: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_ALL_CATEGORY:
            const categories = action.payload.sort((a, b) => a.OrderNumber - b.OrderNumber)
            return { ...state, categories }

        case actionType.ADD_CATEGORY: {
            const categories = [...state.categories];
            categories.push(action.payload);
            categories.sort((a, b) => a.OrderNumber - b.OrderNumber)
            return {
                ...state,
                categories
            };
        }
        case actionType.EDIT_CATEGORY: {
            const categories = [...state.categories];
            categories.find(x => x.Id === action.payload.id)[action.payload.paramName] = action.payload.value;
            return {
                ...state,
                categories
            };

        }

        default:
            return { ...state }
    }
}
export default reducer