import axios from 'axios';
import { url } from '../../axiosInstance';

export const OpenTextGet = () => {

    return axios.get(`${url}OpenText`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })

}


export const OpenTextSet = (data) => {

    return axios.post(`${url}OpenText`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}