import React, { Fragment, useEffect, useState } from 'react';
import MyCropper from "../../tools/MyCropper";
import { globalFileServer } from '../../../../axiosInstance';
import { GetImgUrlProduct, SetImgActive, getItemsPerCategoryCount } from '../../../server/Product'
import showAlert from '../../../server/ShowAlert';
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch, useSelector } from 'react-redux';
import Detailes from '../card/cardDeailes';
import MyCropperComp from '../../tools/MyCrooperComp';

// import ViewThreeSixty from '../../containers/ViewThreeSixty';


const ProductEditImg = () => {

    const { product, stationId } = useSelector(state => ({
        product: state.selectedProduct.product,
        stationId: state.user.stationId
    }));


    const [replace, setReplace] = useState(false);
    const [imagesList, setImagesList] = useState([]);
    const [showInList, setShowInList] = useState([])
    const dispatch = useDispatch();

    const getImgUrl = () => {
        if (product) {
            GetImgUrlProduct(product.Mkt)
                .then(x => {
                    if (x.data) {
                        setImagesList(x.data)
                    }
                    else {
                        showAlert({ msgTitle: "אירעה שגיאה בטעינת תמונות מהמערכת ", msgType: "error" })
                    }
                })
                .catch(() => showAlert());
        }
    }

    useEffect(() => {
        if (product) {
            getItemsPerCategoryCount(product.CategoryId, stationId, 5, product.Mkt)
                .then(x => {
                    setShowInList(x.data)
                })
        }
    }, [product])

    useEffect(() => {
        getImgUrl()
    }, [product])

    const changeActiveImg = (imgData) => {
        dispatch(SetImgActive(product.Mkt, imgData.Id, imgData.Name))
    }

    const uploadImg = (data) => {
        let images = imagesList;
        if (replace) {
            const foundIndex = images.indexOf(replace);
            images[foundIndex] = data;
            setReplace(false);

        } else {
            images = images.map(x => ({ ...x, Active: false }));
            images.push({ ...data, Active: true });
        }
        setImagesList(images);
        setPreload(false);
    }

    return (
        product ?

            <Fragment>
                <div className='flex'>
                    <span>הוסף תמונה</span>
                    <MyCropper
                        uploadImg={uploadImg}
                        itemId={product.Mkt}
                        folder="products"
                        NameId="ProductMkt"
                        Table="p_image_url"
                        beforeFileName={`${product.Mkt}_${product.Barcode}_`}
                    />
                </div>
                <p>כל התמונות במערכת</p>
                {
                    imagesList.length ?
                        <div className='img-list'>
                            {imagesList.map((x) => (
                                <div key={x.Id}>
                                    {!x.Name?.includes('.zip') ?
                                        <div>
                                            <div className={x.Active ? 'img-active' : 'img'} >
                                                <Img
                                                    src={globalFileServer + "products/" + x.Name}
                                                    onClick={() => changeActiveImg(x)}
                                                />
                                            </div>
                                            {x.Name.includes('Gs1') ? <label>תמונה של GS1</label> : null}
                                        </div> :
                                        <div >
                                            {/* <ViewThreeSixty
                                                Path={x.Name}
                                                Barcode={product.Barcode}
                                                amount={40}
                                                imagePath={`${globalFileServer}products/gs1/1233_7290000055893_867943_gs1Id_42997`}
                                                fileName="7290000055893_{index}.jpg"
                                            /> */}
                                        </div>}
                                </div>
                            ))}
                        </div>
                        : <div>אין רשימה להצגה</div>
                }
                <p>הדגמה במערכת  </p>
                {
                    showInList.length > 1 ?
                        // <div className='img-list'>
                        <div className="flex-container">
                            <div className="col-lg-9">
                                {showInList.map((x) => (
                                    <div className='col-lg-2' key={x.Id}>
                                        <div className="item-card role">
                                            <Detailes inCart={false} productPopUp={() => { }} element={x} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : <div>אין רשימה להצגה</div>
                }
            </Fragment >
            : null
    )
}

export default ProductEditImg


const Img = ({ src, onClick, className }) => {

    const [isBig, setIsBig] = useState(false);
    const [srcPrev, setsrcPrev] = useState(src);
    const [cropped, setCropped] = useState(true)

    const SetClick = () => {
        setIsBig(!isBig)
        onClick()
    }

    return (
        <Fragment>
            {/* {isBig ? <PopUp
                // width="200px"

                close={() => setIsBig(false)}>
                <img
                    // className={className}
                    src={src}
                />
                
            </PopUp> : null} */}
            {isBig ? <MyCropperComp
                src={src}
                srcPrev={srcPrev}
                cancel={() => setIsBig(false)}
                setSrcPrev={setsrcPrev}
                save={(data) => console.log("save", data)}
                cropped={cropped}
                setCropped={setCropped}
            />

                : null}
            <img
                className={className}
                src={src}
                onClick={SetClick}
            />
        </Fragment>
    )
}