import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { globalFileIcon } from '../../../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleMenu, setToggleCart, setToggleSidebar } from "../../server/CartsOpen";
import Navigator from "./NAVIGATOR";
import HeaderConnection from "./HeaderConnection";
import HeaderSearch from "./HeaderSearch";
import HeaderFilterAndSort from "./HeaderFilterAndSort"
import HeaderCart from "./HeaderCart"

const Header = ({ currentSubCategory }) => {
	const dispatch = useDispatch();
	const { user, role, productsInCart, toggleMenu, toggleSidebar, deliveryPrice } = useSelector(state => ({
		user: state.user.user,
		role: state.user.role,
		productsInCart: state.productsInCart.productsInCart,
		toggleMenu: state.openCart.toggleMenu,
		toggleSidebar: state.openCart.toggleSidebar,
		deliveryPrice: state.productsInCart.deliveryPrice,
	}));

	const { pathname } = useLocation();

	useEffect(() => {
		if (!role && user) {
			dispatch(setToggleCart(true))
		}
	}, [])

	return (
		<Fragment>
			<header >
				<div className="header-wrapper">
					<HeaderConnection user={user} role={role} />
					{pathname !== '/about' ? <>
						<div className=" head-right-side" >

							<div className="menu" onClick={() => dispatch(setToggleMenu())} >
								{toggleMenu ?
									<img src={globalFileIcon + 'close-menu.svg'} alt="close" />
									:
									<img src={globalFileIcon + 'menu-new.svg'} alt="menu" />
								}
							</div>
							<Navigator currentSubCategory={currentSubCategory}/>
						</div>
						<div className="header-end">
							{role ?
								<div onClick={() => dispatch(setToggleSidebar(!toggleSidebar))} className="toggle-nav">
									{toggleSidebar ?
										<img src={globalFileIcon + 'close.svg'} alt="menu" />
										:
										<img src={globalFileIcon + 'menu_new.svg'} alt="menu" />
									}
									<span className="cross-line">|</span>
								</div>

								: null}
							<HeaderFilterAndSort />
							<span className="cross-line">|</span>
							<HeaderSearch />
							<HeaderCart role={role} user={user} pathname={pathname} productsInCart={productsInCart} deliveryPrice={deliveryPrice} />
						</div>
					</> : null}
				</div>
			</header>
		</Fragment>

	)
}



export default Header;

