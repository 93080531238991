import SweetAlert from 'sweetalert2';

const showAlert = ({ msgTitle, msgText, msgType, msgTimer }) => {
    SweetAlert.fire({
        icon: msgType ? msgType : 'error',
        title: msgTitle ? msgTitle : 'שגיאה בחיבור לרשת',
        text: msgText !== null ? msgText : 'בבקשה נסה שוב מאוחר יותר',
        timer: msgTimer ? msgTimer : 5000,
        showConfirmButton: false,
        didOpen: () => {
            const iconElement = document.querySelector('.swal2-icon');
            if (iconElement) {
                iconElement.style.cursor = 'pointer';
                iconElement.addEventListener('click', () => {
                    SweetAlert.close(); 
                });
            }
        }
    });
};

export default showAlert;

export const showAlertHTML = (msgHtml) => {
    SweetAlert.fire({
        html: msgHtml,
        showConfirmButton: false,
        showCloseButton: true
    })
}
