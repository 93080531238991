import React, { useEffect, useState } from 'react'
import { globalFileServer } from "../../../../axiosInstance";
import showAlert from '../../../server/ShowAlert';
import { UploadImageData } from '../../../server/UploadImage'


const AddImg = ({ Id, FileUrl, saveChange }) => {
  const [FileName, setFileName] = useState('');
  const [File, setFile] = useState(null);
  const [PathUrl, setPathUrl] = useState(FileUrl);

  useEffect(() => {
    setPathUrl(FileUrl)
  }, [FileUrl])

  const send = () => {
    const formData = new FormData();
    formData.append('Image', File);
    formData.append('FileName', FileName);
    formData.append('ItemId', Id ? Id : 0);
    UploadImageData(formData).then(x => saveChange(x.data));
    showAlert({ msgTitle: 'התמונה נוספה בהצלחה', msgType: 'success' })
  }
  const setName = (target) => {
    setFile(target.files[0]);
    setFileName((target.files[0].name).split(".")[0])
  }
  return (
    <div className='add-img'>
      <input className='add-file' type="file" onChange={({ target }) => setName(target)} />
      <div className='send'>
        <input value={FileName} placeholder="הכנס שם קובץ" onChange={({ target }) => setFileName(target.value)} />
        {PathUrl ? <img
          className="main-img"
          alt={PathUrl}
          src={globalFileServer + 'iconcss/' + PathUrl}
        /> : null}
        <button onClick={send} className='btn send'>שליחה</button>
      </div>

    </div>
  )
}

export default AddImg
