import React, {  useState } from "react";
import { globalFileIcon } from '../../../axiosInstance';
import Search from "../containers/Search";

const HeaderSearch = () => {
    const [showComponent, setShowComponent] = useState(false);

    const goSearch = () => {
		setShowComponent(!showComponent)
	}
    return (
        <>
            <div className="desk-search">
                <Search />
            </div>
            <div className="mobile-search">
                <button className="icon" onClick={goSearch}>
                    <img  loading="lazy" src={globalFileIcon + 'search.svg'} alt="search" />
                </button>
                {showComponent ?
                    <div className="search">
                        <Search />
                    </div> : null
                }
            </div>
        </>
    )
}

export default HeaderSearch
