import axios from 'axios';
import { url } from '../../axiosInstance';

export const GetShippingAddress = ( userId) => {
    return axios.get(`${url}ShippingAddress/GetShippingAddress?customerId=${userId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}


export const ShippingAddressDelete = ( userId, itemId) => {
    return axios.get(`${url}addressNotActive?customerId=${userId}&shippingaddressId=${itemId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const ShippingAddressMain = (itemId, userId) => {
    return axios.get(`${url}addressMain?shippingaddressId=${itemId}&customerId=${userId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const ShippingAddressPost = ( data) => {
    return axios.post(`${url}ShippingAddress`,
        data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
