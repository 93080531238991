import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getListOfNewImage } from '../../../server/ListProductNewImage'
import Input from '../../containers/Input';

const ListProductNewImage = () => {
    const navigate = useNavigate()
    const [productList, setProductList] = useState([]);
    const [inputMkt, setInputMkt] = useState("");
    const [selectProd, setSelectProd] = useState("")

    useEffect(() => { GetListOfNewImage() }, [])



    const GetListOfNewImage = () => {
        getListOfNewImage()
            .then(x => {
                // sort with fatherCategoryId & categoryId
                const listImg = (x.data.sort((a, b) => (a.CategoryFullOrderNumber > b.CategoryFullOrderNumber)))
                //(a, b) => (a.CategoryFather1OrderNumber ? a.CategoryFather1OrderNumber : a.CategoryFatherOrderNumber > b.CategoryFather1OrderNumber ? b.CategoryFather1OrderNumber : b.CategoryFatherOrderNumber))
                //            ).sort((a, b) => (a.CategoryFather1OrderNumber ? a.CategoryFatherOrderNumber : a.CategoryOrderNumber > b.CategoryFather1OrderNumber ? b.CategoryFatherOrderNumber : b.CategoryOrderNumber))
                //            ).sort((a, b) => (a.CategoryFather1OrderNumber ? a.CategoryOrderNumber : a.orderNumber > b.CategoryFather1OrderNumber ? b.CategoryOrderNumber : b.orderNumber))
                //            ).sort((a, b) => (a.orderNumber > b.orderNumber))
                //).sort(
                // const listImg = (((x.data.sort((a, b) => (a.CategoryFather1OrderNumber?a.CategoryFather1OrderNumber:a.CategoryFatherOrderNumber - b.CategoryFather1OrderNumber?b.CategoryFather1OrderNumber:b.CategoryFatherOrderNumber) ? 1 : -1)
                //         ).sort((a, b) => (a.CategoryFather1OrderNumber?a.CategoryFatherOrderNumber:a.CategoryOrderNumber - b.CategoryFather1OrderNumber?b.CategoryFatherOrderNumber:b.CategoryOrderNumber) ? 1 : -1)
                // ).sort((a, b) => (a.CategoryFather1OrderNumber?a.CategoryOrderNumber:a.orderNumber - b.CategoryFather1OrderNumber?b.CategoryOrderNumber:b.orderNumber) ? 1 : -1)
                // ).sort((a, b) => (a.orderNumber -b.orderNumber) ? 1 : -1)
                setProductList(listImg)
            })
            .catch(() => console.log("error"))
    }
    const CheckProduct = (Mkt) => {
        navigate(`/edit-product/${Mkt}/img`);
    }

    const SearchProd = (value) => {
        const prod = productList.filter(x => x.Mkt === parseInt(value) || x.Mkt.toString().includes(value) || x.Barcode.toString().includes(value))
        if (prod.length > 0) {
            setInputMkt(value);
            setSelectProd(prod)
        }
        else {
            setInputMkt(value);
        }
    }

    return (
        <div className="new-image">
            <h1 className='title-role'>בחר תמונה חדשה עבור המוצרים הבאים</h1>
            <div className="search">
                <Input
                    Placeholder=" הכנס מק״ט או ברקוד לחיפוש מוצר"
                    Type="number"
                    Value={inputMkt}
                    ClassName="search-input"
                    InputChange={(value) => SearchProd(value)}
                />
            </div>
            <div className='image-list'>
                {inputMkt.length >= 2 ?
                    selectProd.map(product => (
                        <button key={product.Mkt} onClick={() => CheckProduct(product.Mkt)}>{product.ProductFullDescription}</button>
                    ))
                    :
                    productList.map(product => (
                        <button key={product.Mkt} onClick={() => CheckProduct(product.Mkt)}>{product.ProductFullDescription}</button>
                    ))
                }
            </div>
        </div>
    )
}

export default ListProductNewImage