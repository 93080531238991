import React from 'react'
import { globalFileIcon } from '../../../axiosInstance';

const PopUp = ({ close, width, children }) => (
    <div className="popup">
        <div className="popup-wrapper animated" style={{ width: width }}>
            {close ? <div onClick={close} className="close-popup">
                <img  loading="lazy" src={globalFileIcon + 'cross-popup.svg'} alt="close" />
            </div> : null}
            <div className='children-popup'>{children}</div>
        </div>
    </div>
);

export default PopUp;

export const SystemMessagePopUp = ({ close, width, children }) => (
    <div className="popup">
        <div className="popup-wrapper animated"
            style={{
                backgroundImage: `url(${globalFileIcon + 'AlertBg.svg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundColor: 'transparent',
                width: width,
                height: '450px'
            }}>
            {close ? <div onClick={close} className="close-popup system">
                <img  loading="lazy" src={globalFileIcon + 'cross-popup.svg'} alt="close" />
            </div> : null}
            <div className='system-popup-child'>{children}</div>
        </div>
    </div>
);