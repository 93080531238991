import React, { useState } from 'react'
import { setColumnsNew, setRowsNew, AddNewCells } from '../../../server/Station';
import { globalFileIcon } from '../../../../axiosInstance';
import showAlert from '../../../server/ShowAlert';

const AddPlace = ({ selectStation, LocationColumnId, LocationRowId, setAddNew }) => {
    const [countColumns, setCountColumns] = useState('');

    const addLocation = () => {
        if (countColumns) {
            setColumnsNew(selectStation, countColumns)
                .then(() => {
                    showAlert({ msgTitle: "הטורים התווספו בהצלחה", msgType: "success" })
                    setAddNew("locations")

                })
                .catch(() => showAlert({ msgTitle: "לא הצלחנו להוסיף טורים", msgType: "error" }))
        }
        else {
            showAlert({ msgTitle: "יש לבחור כמות", msgType: "error" })
        }

    }
    const addRows = () => {
        if (countColumns) {
            setRowsNew(selectStation, countColumns, LocationColumnId)
                .then(() => {
                    showAlert({ msgTitle: "הקומות התווספו בהצלחה", msgType: "success" })
                    setAddNew("rows")
                })
                .catch(() => showAlert({ msgTitle: "לא הצלחנו להוסיף קומות", msgType: "error" }))
        }
        else {
            showAlert({ msgTitle: "יש לבחור כמות", msgType: "error" })
        }
    }
    const addCells = () => {
        if (countColumns) {
            AddNewCells(selectStation, countColumns, LocationColumnId, LocationRowId)
                .then(() => {
                    setAddNew("cells")
                    showAlert({ msgTitle: "הארגזים התווספו בהצלחה", msgType: "success" })
                })
                .catch(() => showAlert({ msgTitle: "לא הצלחנו להוסיף ארגזים", msgType: "error" }))
        }
        else {
            showAlert({ msgTitle: "יש לבחור כמות", msgType: "error" })
        }
    }
    return (
        <div className='add-place'>
            <span className='add-place-btn'>
                <input value={countColumns} onChange={({ target }) => setCountColumns(target.value)} />
                <span className='add-cul-floor-btn'>
                    {!LocationColumnId ? <button type='button' onClick={addLocation}>
                        <img  loading="lazy" src={globalFileIcon + 'plus.svg'} alt="" /> טורים</button> : null}
                    {!LocationRowId ? <button type='button' onClick={addRows}>
                        <img  loading="lazy" src={globalFileIcon + 'plus.svg'} alt="" /> קומות</button> : null}
                    <button type='button' onClick={addCells}>
                        <img  loading="lazy" src={globalFileIcon + 'plus.svg'} alt="" /> ארגזים</button>
                </span>
            </span>
            <label className='note'> יש להכניס את המינימלי ואח"כ בכל טור להוסיף עוד</label>
        </div>
    )
}

export default AddPlace
