import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import PopUp from "../containers/PopUp";



const MyCropperComp = ({ save, cancel, src, srcPrev, setSrcPrev, cropped, setCropped }) => {

    // const img = new Image();
    // img.onload = (x) => {
    //     console.log(x)
        
    //     // your code
    // };
    // img.crossOrigin = 'Anonymous';
    // img.src = src;

    const cropper = useRef(null);


    const _cropChange = () => {
        if (cropped) {
            setCropped(false);
        }
    }


    const _crop = () => {
        const src = cropper.current.cropper.getCroppedCanvas().toDataURL();
        setCropped(true);
        setSrcPrev(src);
    }

    return (

        src ?
            <PopUp width='900px'>
                <div className="flex-container">
                    <div className="col-lg-6 for-crop">
                        <div className="box-2"></div>
                        <div className="box-3"></div>
                        <Cropper
                            src={src}
                            aspectRatio={16 / 16}
                            guides={false}
                            checkCrossOrigin={"Anonymous"}
                            ref={cropper}
                            crop={_cropChange}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className='image-preview'>
                            <img loading="lazy" src={srcPrev} alt="the current Img" />
                        </div>
                    </div>
                </div>
                <ul className="actions">
                    <li>
                        {cropped ?
                            <button onClick={save} type="button" className="button-green">שמור</button> :
                            <button onClick={_crop} type="button" className="button-green">גזור</button>
                        }
                        <button onClick={cancel} type="button" className="button-red">ביטול</button>
                    </li>
                </ul>
            </PopUp> : null)

}

export default MyCropperComp