import React from 'react';

const ProductInfo = ({ selectedProd }) => {
    return (
        <>
            <div className="prod-info-line">
                <p className="prod-brand">{selectedProd.Brand}</p>
                <span className='line'>|</span>

                {selectedProd.PackageQuantity ?
                    <div>
                        <span className='weight-quantity'>{selectedProd.WeightQuantity }</span>
                        <span>{selectedProd.UnitMeasure}</span>
                    </div>
                    :
                    <div>
                        <span className='weight-quantity'>{selectedProd.WeightQuantity}</span>
                        <span>{selectedProd.UnitMeasure}</span>
                    </div>
                }
                {selectedProd.DairyName ? <div >
                    <span className='line'>|</span>

                    <span className={
                        selectedProd.DairyId === 3 ? 'fleshy' :
                            selectedProd.DairyId === 2 ? 'milky' :
                                'parve'}
                    >{selectedProd.DairyName}</span>
                </div>:null}
            </div>
            <h4 className='prod-name'>{selectedProd.ProductName}</h4>
            <h4 className='prod-sale'>{selectedProd.SaleText}</h4>
        </>
    )
}
export default ProductInfo;