import React from 'react'

const Input = ({ Title, Placeholder, Type, Value, ClassName, Disabled, Checked, InputChange }) => {

    return (
        <div className='input-class'>
            {Title ? <h2>{Title}</h2> : null}
            <input
                placeholder={Placeholder}
                type={Type}
                value={Value ? Value : ''}
                className={ClassName}
                disabled={Disabled ? true : false}
                checked={Checked}
                onChange={(e) => InputChange(e.target.value)}
            />
        </div>
    )
}
export default Input

export const InputButton = ({Type,Label,ClassName,Disabled,OnClick}) => {

    return (
        <div className='input-class-btn'>
            <input
                type={Type}
                value={Label}
                className={ClassName}
                disabled={Disabled ? true : false}
                onClick={(e) => OnClick(e.target.value)}
            />
        </div>
    )
}