import React ,{useContext}from 'react';
import { useForm } from 'react-hook-form'
import { globalFileServer } from '../../../../axiosInstance'
import { SendMail } from '../../../server/Mail'
import showAlert from '../../../server/ShowAlert'
import Input from "../../containers/InputForm";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { setPreload } from '../../../server/CartsOpen'
import {GlobalContext} from '../../customerService/GlobalContext'

const schema = yup.object({
	Mail: yup.string().required("מייל חובה").email("כתובת מייל"),
	Name: yup.string().required("שם חובה").test('len', "אורך מקסימלי של בין 3-20 אותיות", x => x.length > 3 && x.length < 20),
	Phone: yup.string().required("חובה להכניס טלפון").test('len', "אורך מקסימלי של 20", x => x.length >= 9 && x.length <= 10),
	Message: yup.string().required("חובה להכניס הודעה").test('len', "אורך מקסימלי של 20", x => x.length <= 150)
}).required();


const Contacts = ({ home }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema)
	});

	const { email } = useContext(GlobalContext);



	const sendForm = (data) => {
		dispatch(setPreload(true))
		SendMail(data)
			.then(() => {
				showAlert({ msgTitle: 'הודעה נשלחה בהצלחה', msgType: 'success' });
				reset();
			})
			.catch(() => {
				dispatch(setPreload(false))
			})
			.finally(() => dispatch(setPreload(false)));
	}

	return (
		<div className="contacts-company">
			{!home ? <h3 className="title">צרו קשר</h3> : null}
			<div className="container">
				<div className="flex-container info">
					<a href="tel:+97225327277" target="_blank" className="col-lg-6">
						<div className="wr">
							<div className="flex-container">
								{/* <div className="col-lg-3">
									<div className="img">
										<img  loading="lazy" src={globalFileIcon + 'phone.svg'} alt="Phone" />
									</div>
								</div> */}
								<div className="col-lg-9">
									<div className="text">
										<h3>טלפון</h3>
										<p>02-5327277</p>
									</div>
								</div>
							</div>
						</div>
					</a>
					<a href={`mailto:${email}`} target="_blank" className="col-lg-6">
						<div className="wr">
							<div className="flex-container">
								{/* <div className="col-lg-3">
									<div className="img">
										<img  loading="lazy" src={globalFileIcon + 'mail.svg'} alt="mail" />
									</div>
								</div> */}
								<div className="col-lg-9">
									<div className="text">
										<h3>אימייל</h3>
										<p>{email}</p>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				<form className="flex-container form" onSubmit={handleSubmit(sendForm)}>
					<div className="col-lg-6">
						<div className="wr">
							<div className="input-group">
								<Input
									errors={errors}
									label="שם"
									name="Name"
									register={register}
									type="text"

								/>
							</div>
							<div className="input-group">
								<Input
									errors={errors}
									label="טלפון"
									name="Phone"
									register={register}
									type="text"
								/>

							</div>
							<div className="input-group">
								<Input
									errors={errors}
									label="מייל"
									name="Mail"
									register={register}
									type="text"
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="wr">
							<div className="input-group">
								<Input
									errors={errors}
									type="textarea"
									label="הודעה"
									name="Message"
									register={register}
								/>
							</div>
							<div className="button-wrapper">
								<button type='submit' onClick={sendForm}>שלח הודעה</button>
							</div>
						</div>
					</div>
				</form>
			</div>

		</div>
	)
}

export default Contacts