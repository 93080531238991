import axios from 'axios';
import { url } from '../../axiosInstance';

export const getAllAdvertising = () => {
    return axios.get(`${url}Advertising`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getAllPerCategoryId = (CategoryId) => {
    return axios.get(`${url}Advertising?CategoryId=${CategoryId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const Save = (data) => {
    return axios.post(`${url}Advertising`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })


}