import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import Input, { InputButton } from '../../containers/Input';



const InventoryInfo = ({
    inputGeneralInventory, ProductFullDescription, inputMkt, inputBarcode,
    showUnavailableInventory, setShowUnavailableInventory,
    inputAvailableInventory, SearchProd,
    setInputMktOrBarcode, inputMktOrBarcode}) => {
    const navigate = useNavigate()


    const NextPath = (moveTo) => {
        navigate(`/edit-product/${moveTo}`);
    }
    return (
        <Container>
            <Row>
            <Col sm={5}>
                    <div className="input mkt-or-barcode" onKeyDown={(e) => e.key === 'Enter' ? SearchProd() : null}>
                        <Input
                            Title="מק״ט או ברקוד"
                            Type="number"
                            Value={inputMktOrBarcode}
                            InputChange={(value) => setInputMktOrBarcode(value)}
                        />
                    </div>
                </Col>
                <Col sm={1.5}>
                    <div className="input-btn" onClick={SearchProd}>
                        <button className='btn' >
                            חפש
                        </button>
                    </div>
                </Col>
                {ProductFullDescription ?
                    <Col sm={1.5}>
                    <div className="input-btn">
                        <InputButton
                            Type="button"
                            Label="כרטיס פריט"
                            ClassName='btn'
                            OnClick={() => NextPath(inputMkt)}
                        />
                    </div>
                </Col>:null
                }
            </Row>
            {ProductFullDescription ?
                <Row className='search-row'>
                    <Col sm={4}>
                        <div className="input">
                            <h2>שם מוצר:</h2>
                            <label
                                type="text"
                                disabled={true}>
                                {ProductFullDescription}
                            </label>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <div className="input">
                            <h2>מלאי זמין:</h2>
                            <label
                                type="text"
                                disabled={true}>
                                {inputAvailableInventory}
                            </label>
                        </div>
                    </Col>
                    <Col sm={2}>
                        <div className="input">
                            <h2>מלאי כללי:</h2>
                            <label
                                type="text"
                                disabled={true}
                            >
                                {inputGeneralInventory}
                            </label>
                        </div>
                    </Col>
                    <Col sm={2.5}>
                        <div className="input-checkbox">
                            <Input
                                Title="הצג מלאי לא זמין:"
                                Type="checkbox"
                                ClassName='checkbox'
                                Checked={showUnavailableInventory}
                                InputChange={() => setShowUnavailableInventory(!showUnavailableInventory)}
                            />
                        </div>
                    </Col>
                </Row>
                : null}
                {ProductFullDescription ?
                    <Row>
                     <Col sm={3}>
                        <div className="input">
                            <h2> מק״ט:</h2>
                            <label
                                type="text"
                                disabled={true}>
                                {inputMkt}
                            </label>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="input">
                            <h2>ברקוד:</h2>
                            <label
                                type="text"
                                disabled={true}>
                                {inputBarcode}
                            </label>
                        </div>
                    </Col>
                </Row> 
                :null}
        </Container>
    )
}

export default InventoryInfo
