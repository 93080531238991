import React from 'react';
import { setUserEntry } from '../../server/Customer'
import { useDispatch } from 'react-redux';
import UserSighIn from './UserSighin';
import UserLogin from './UserLogin';
import PopUp from '../containers/PopUp';

const UserEntry = ({typeOfUser}) => {
	const dispatch = useDispatch();

	return (
		<PopUp width='700px' close={() => dispatch(setUserEntry(false))}>
			<div className="user-entry">
				<div className='user-entry-header'>
				<h3>כמה פרטים קצרים ויוצאים לדרך</h3>
				<div className='tab-header'>
					<button onClick={() => dispatch(setUserEntry([true, "login"]))} className={typeOfUser === "login" ?'tab login':'tab'}>כניסה לחשבון</button>
					<button onClick={() => dispatch(setUserEntry([true, "signIn"]))} className={typeOfUser === "signIn" ?'tab signIn':'tab'}>יצירת חשבון חדש</button>
				</div>
				</div>
				{typeOfUser === "login" ?
					<UserLogin  />
					: <UserSighIn parentName='signIn' />}

			</div>
		</PopUp>
	)
}

export default UserEntry