import React, { useState } from "react";
import showAlert from "../../server/ShowAlert";
import { globalFileServer } from "../../../axiosInstance";
import { setPreload } from "../../server/CartsOpen";
import { useDispatch } from "react-redux";
import { UploadImage } from "../../server/UploadImage";
import MyCropperComp from "./MyCrooperComp";

const MyCropper = ({
  itemId,
  folder,
  uploadImg,
  beforeFileName,
  NameId,
  Table,
  type,
}) => {
  const dispatch = useDispatch();
  const [cropped, setCropped] = useState(false);
  const [src, setSrc] = useState(null);
  const [srcPrev, setSrcPrev] = useState(null);

  const save = () => {
    const fileName =
      new Date()
        .toLocaleTimeString("he-Il", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })
        .split(":")
        .join("") + (type ? type : ".jpg");

    dispatch(setPreload(true));
    const val = {
      FileName: beforeFileName + fileName,
      PathUrl: srcPrev,
      Folder: folder,
      ItemId: itemId,
      NameId,
      Table,
      Id: itemId,
      Name: beforeFileName + fileName,
    };

    UploadImage(val)
      .then(() => uploadImg(val))
      .catch(() => showAlert())
      .finally(() => cancel());
  };

  const cancel = () => {
    setSrc(null);
    setSrcPrev(null);
    dispatch(setPreload(false));
  };

  const _onChange = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      let file;
      if (e.dataTransfer) {
        file = e.dataTransfer.files[0];
      } else if (e.target) {
        file = e.target.files[0];
      }
      if (file.size > 9000000) {
        showAlert({
          msgTitle: "הקובץ חורג מהמשקל 8 mb",
          msgText: "יש לנסות להעלות קובץ שוב",
          msgType: "info",
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setSrc(reader.result);
          setSrcPrev(reader.result);
          setCropped(true);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className="load-image-wrapper">
      <div className="addImg-custom">
        <div className="plus">
          <img
            loading="lazy"
            src={`${globalFileServer}iconcss/plus-white.svg`}
            alt="icons plus-white"
          />
        </div>
        <img
          loading="lazy"
          src={`${globalFileServer}iconcss/placeholder.svg`}
          alt=" icons placeholder"
        />
        <input type="file" className="upload" onChange={_onChange} />
      </div>
      <MyCropperComp
        save={save}
        cancel={cancel}
        src={src}
        srcPrev={srcPrev}
        setSrcPrev={setSrcPrev}
        cropped={cropped}
        setCropped={setCropped}
      />
    </div>
  );
};

export default MyCropper;
