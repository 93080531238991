import axios from 'axios';
import { url } from '../../axiosInstance';

export const SendMail = ( data) => {
    return axios.post(`${url}mail`,data ,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}