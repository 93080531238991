import axios from 'axios';
import { url } from '../../axiosInstance';

export const LinkedProducts = ( data) => {
    return axios.post(`${url}LinkedProducts`,
        data,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}
export const LinkedProductsRemove = ( data) => {
    return axios.post(`${url}LinkedProducts/remove`,
        data,
        {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
}