import React, { useState, useEffect } from 'react';
import { InventoryPerMkt } from '../../../server/InventoryUpdate'
import { TimeDateMonthYear } from '../../containers/Date'
import { useSelector } from 'react-redux'

const ProductLocationPopUp = ({ product }) => {

	const [location, setLocation] = useState([]);
	const [hasReturn, setHasReturn] = useState(false);
	const { stationId, selectedProduct } = useSelector(state => ({
		stationId: state.user.stationId,
		selectedProduct: state.selectedProduct.product
	}))
	if (!product && selectedProduct) {
		product = selectedProduct
	}
	useEffect(() => {
		InventoryPerMkt(product.Mkt, stationId)
			.then(x => {
				const data = x.data.filter(x => x.StokeTypeId === 1);
				setLocation(data)
				setHasReturn(true);
			})
			.catch(() => console.log("error"));
	}, [])

	return (
		<div className="product-location">
			<div className='product-header'>
				<h2>כמות בפועל: {product.Quantity}</h2>
				<h2>כמות זמינה להזמנות: {product.QuantityCanOrder}</h2>
			</div>
			<div className='table-location'>
				<table>
					<tbody>
						<tr>
							<th>שם מוצר</th>
							<th>מק״ט</th>
							<th>ברקוד</th>
							<th>שם מיקום</th>
							<th>כמות</th>
							<th>כמות זמין</th>
							<th>תוקף</th>
							<th>מצב מלאי</th>
						</tr>
						{location.length ? location.map(x => <tr key={x.Id}>
							<td>{x.ProductFullDescription}</td>
							<td>{x.ProductMkt}</td>
							<td>{product.Barcode}</td>
							<td>{x.FullLocationName}</td>
							<td>{x.Quantity}</td>
							<td>{x.QuantityCanCollect}</td>
							<td>{x.Expired ? TimeDateMonthYear(x.Expired) : null}</td>
							<td>{x.StockTypeName}</td>
						</tr>
						) : hasReturn ? <tr><td >לא נמצא מיקומים במלאי</td></tr> : null}
					</tbody>
				</table >
			</div>
		</div>
	)
}

export default ProductLocationPopUp 