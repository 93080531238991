import * as actionType from './action'

const initialState = {
    product: null,
    ShowImgList: true,
    products: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_SELCTED_PRODUCT:
            return { ...state, product: action.data.Product, }
        case actionType.SET_UNSELCTED_PRODUCT:
            return { ...state, product: null }
        case actionType.SET_ALL_SELECTED_PRODUCT:
            return { ...state, products: action.data }
        case actionType.SET_UPDAATE_SELCED_PRODUCT: {
            const oldProduct = { ...state.product };
            oldProduct.ImageUrl = [action.imgName];
            return { ...state, product: oldProduct, ShowImgList: false }
        }
        default:
            return state
    }
}
export default reducer;