import React, { Fragment, useState } from 'react';
import { NavLink } from "react-router-dom";
import UserSignIn from '../header/UserSighin';
import DetailsWindow from '../containers/DetailsWindow';
import { globalFileIcon} from '../../../axiosInstance';

const PrivateAreaList = ({setOpenPrivateAreaList}) => {

    return (
        <div className='private-area-list'> 
                <NavLink onClick={()=>setOpenPrivateAreaList(false)}  to="/shopping-list">
                    <img  loading="lazy" src={globalFileIcon + 'listInMemu.svg'} alt="list" />
                    הרשימות שלי
                </NavLink>  
                <NavLink onClick={()=>setOpenPrivateAreaList(false)}  to="/history">
                    <img  loading="lazy" src={globalFileIcon + 'orderMenu.svg'} alt="order" />
                    ההזמנות שלי
                </NavLink>  
                 {/* <NavLink onClick={()=>setOpenPrivateAreaList(false)}  to="/">
                    <img  loading="lazy" src={globalFileIcon + 'trackingMenu.svg'} alt="tracking" />
                        מעקב משלוחים
                </NavLink> */}
                <NavLink onClick={()=>setOpenPrivateAreaList(false)}  to="/private-area">
                    <img  loading="lazy" src={globalFileIcon + 'privateAreaInMenu.svg'} alt="private-area" />
                        פרטים אישיים
                </NavLink>
                <NavLink onClick={()=>setOpenPrivateAreaList(false)}  to="/private-area/address">
                <img  loading="lazy" src={globalFileIcon + 'addressInMenu.svg'} alt="address" />
                     כתובת למשלוח 
                </NavLink>

							
            <div className='close-menu' 
            onClick={()=>setOpenPrivateAreaList(false)}
            style={
                {backgroundImage: `url(${globalFileIcon + 'closePrivateArea.svg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundColor: 'transparent',
                }}
            >
            </div>
        </div>
    )

}
export default PrivateAreaList