import React from 'react';
const EditorForTitle = ({ paramName,  element, elementTable,
	dist, editItems, updateItems, placeholder, tag }) => {


	const handleChange = (e) => {
		let data = {
			itemId: element.Id,
			value: e.target.value,
			paramName: paramName,
			elementTable: elementTable ? elementTable : null
		}
		if (dist) {
			data.dist = dist;
		}
		editItems(data);
	}
	const onBlur = (e) => {
		let data = {
			itemId: element.Id,
			value: e.target.value,
			paramName: paramName,
			elementTable: elementTable ? elementTable : null
		}
		if (dist) {
			data.dist = dist;
		}
		updateItems(data);
	}

	return (
		<div className="editor-for-title">
			<input
				value={element[paramName] ? element[paramName] : ''}
				onChange={handleChange}
				onBlur={onBlur}
				type="text"
				placeholder={placeholder ? placeholder : 'כותרת פריט'}
				className={tag ? tag : null}
			/>
		</div>
	);
}

export default EditorForTitle