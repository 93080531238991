import React, { Fragment, useState } from 'react';
import { multiMoves, updateDateEndSpecial } from '../../../server/InventoryUpdate'
import showAlert from '../../../server/ShowAlert'
import TimeYearMonthDate from '../../containers/Date'
import { useDispatch, useSelector } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';

const InventoryTable = ({ inventory, Cancel, showUnavailableInventory, setInventory, SearchProd }) => {
    const dispatch = useDispatch();
    const stock_type = useSelector(state => state.parameters.stock_type)

    const [editChange, setEditChange] = useState(0);
    const [quantityCanCollectFrom, setQuantityCanCollectFrom] = useState(0);
    const [fromCheckBox, setFromCheckBox] = useState(null)
    const HandleMoveFromChange = (checkedItem, quantityCanCollect, quantity) => {
        const inventories = [...inventory]
        inventories.forEach(element => {
            if (element.QuantityToMove) { element.QuantityToMove = 0 }
            if (element.InventoryAdd) { element.InventoryAdd = element.QuantityCanCollect ? element.QuantityCanCollect : element.Quantity }
        })
        setInventory(inventories);
        setFromCheckBox(checkedItem);
        setEditChange(quantityCanCollect ? quantityCanCollect : quantity);
        setQuantityCanCollectFrom(quantityCanCollect ? quantityCanCollect : quantity)
    }


    const HandleQuantityToAdd = (value, changeInventoryId, toQuantityCanCollect) => {
        const inventories = [...inventory]
        inventories.find(element => element.CellId === changeInventoryId).QuantityToMove = value;
        inventories.find(element => element.CellId === changeInventoryId).inventoryChange = true
        let calcEditChange = 0;
        if (fromCheckBox) {
            const total = inventories.reduce((total, current) => {
                if (current.Id !== fromCheckBox && current.QuantityToMove) {
                    total += parseFloat(current.QuantityToMove)
                };
                return total;
            }, 0);
            calcEditChange = quantityCanCollectFrom - total
            if (total > quantityCanCollectFrom && !inventories.find(element => element.Id === fromCheckBox).CanBeMinus) {

                inventories.find(element => element.CellId === changeInventoryId).QuantityToMove = value - (total - quantityCanCollectFrom);
                calcEditChange = 0;
            }
        }
        const index = inventories.findIndex(element => element.Id === changeInventoryId)
        const item = { ...inventory[index] };
        item.InventoryAdd = toQuantityCanCollect ? parseFloat(item.QuantityToMove) + parseFloat(toQuantityCanCollect) : parseFloat(item.QuantityToMove);
        inventories[index] = item;
        setEditChange(calcEditChange)
        setInventory(inventories)

    }

    const HandleStokeTypeId = (value, checkedItem, type) => {
        const inventories = [...inventory];

        const index = inventories.findIndex(x => x.Id === checkedItem);
        const inventoryEdit = { ...inventories[index] };
        inventoryEdit[type] = value;
        inventoryEdit.StokeTypeIdChanged = true;
        inventoryEdit.UpdateChanges = true;
        inventoryEdit.inventoryChange = true;
        inventories[index] = inventoryEdit;
        setInventory(inventories)
    }

    const SaveProd = () => {
        dispatch(setPreload(true));
        const inventories = [...inventory]
        let changesInventory = [];
        const from = inventories.find(e => e.Id === fromCheckBox)

        inventories.forEach(element => {
            if (element.QuantityToMove > 0 && fromCheckBox) { element.Expired = from.Expired }
        })
        inventory.forEach(element => {
            if (element.QuantityToMove > 0) { changesInventory.push(element) }
        })
        let val = {
            From: from ? from : null,
            Moves: changesInventory,
            fromWhere: 1,
        };
        if (!changesInventory.length) {
            showAlert({ msgTitle: "אין נתונים לשמירה ", msgText: "אולי התכוונת לעדכן שינויים?", msgType: "error" })
        }
        else {
            multiMoves(val)
                .then(x => {
                    if (x.data) {
                        showAlert({ msgTitle: "הנתונים נשמרו בהצלחה", msgType: "success" })
                        SearchProd();
                    }
                    else { showAlert({ msgTitle: "הייתה בעיה בשמירת הנתונים", msgType: "error" }) }
                })
                .catch(() => showAlert({ msgTitle: "הייתה בעיה בשמירת הנתונים", msgType: "error" }))
                .finally(() => dispatch(setPreload(false)));
        }
    }

    const SaveChanges = () => {
        dispatch(setPreload(true));
        const inventories = [...inventory]
        let changesUpdates = [];
        inventories.forEach(element => {
            if (element.UpdateChanges) {
                changesUpdates.push({ Id: element.Id, Expired: element.Expired, StokeTypeId: element.StokeTypeId, SpecialId: element.SpecialId })
            }
            return changesUpdates
        })
        if (!changesUpdates.length) {
            showAlert({ msgTitle: "אין נתונים לעדכון שינויים ", msgText: "אולי התכוונת  לשמור?", msgType: "error" })

        }
        else {
            updateDateEndSpecial(changesUpdates)
                .then(x => {
                    showAlert({ msgTitle: "הנתונים נשמרו בהצלחה", msgType: "success" })
                    SearchProd()
                })
                .catch((e) => {

                    showAlert({ msgTitle: "הייתה בעיה בשמירת הנתונים", msgType: "error" })
                })
                .finally(() => dispatch(setPreload(false)));
        }
    }
    return (
        <Fragment>


            <div className='table-data' >
                <table>
                    <tbody>
                        <tr>
                            <th>העבר מ</th>
                            <th>שם מיקום</th>
                            <th>כמות נוכחית</th>
                            <th>כמות זמינה לליקוט</th>
                            <th>כמות להוספה</th>
                            <th>פג תוקף</th>
                            <th>הגדרות נוספות</th>
                            <th>מצב מלאי</th>
                        </tr>
                        {inventory.length ? inventory.map(x =>
                            x.UpdateChanges || x.StokeTypeId === 1 || showUnavailableInventory || x.checkedCellId ?
                                <tr key={x.CellId} className={fromCheckBox === x.Id ? 'tr-from' : x.inventoryChange ? 'tr-to' : 'tr'}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="move-from"
                                            checked={fromCheckBox === x.Id}
                                            onChange={() => HandleMoveFromChange(x.Id, x.QuantityCanCollect, x.Quantity)}
                                        />
                                    </td>
                                    <td>{x.FullLocationName}</td>
                                    <td>{x.Quantity}</td>
                                    <td>
                                        {x.QuantityCanCollect ? x.QuantityCanCollect : 0}
                                    </td>
                                    <td>
                                        <div className='quantity-to-add'>
                                            <div className='changes'>
                                                ({fromCheckBox === x.Id ? editChange : x.InventoryAdd ? x.InventoryAdd : x.QuantityCanCollect ? x.QuantityCanCollect : 0})
                                            </div>
                                            <input
                                                type='number'
                                                className='input-quantity-to-add'
                                                onChange={(e) => HandleQuantityToAdd(e.target.value, x.CellId, x.Quantity)}
                                                disabled={fromCheckBox === x.Id}
                                                value={fromCheckBox === x.Id ? "" : x.QuantityToMove ? x.QuantityToMove : ''}
                                            >
                                            </input>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type='date'
                                                value={
                                                    fromCheckBox && x.QuantityToMove ? TimeYearMonthDate(inventory.find(element => element.Id === fromCheckBox).Expired)
                                                        :
                                                        x.Expired ? TimeYearMonthDate(x.Expired) : ""}
                                                disabled={fromCheckBox === x.Id ? true : false}
                                                onChange={e => HandleStokeTypeId(e.target.value, x.Id, 'Expired')}
                                            />
                                        </div>
                                    </td>
                                    <td>{x.SpecialName}</td>
                                    <td>
                                        {fromCheckBox === x.Id ?
                                            <div>
                                                {x.StockTypeName}
                                            </div>
                                            : <div>
                                                <select
                                                    className='select-stock-type'
                                                    onChange={(e) => HandleStokeTypeId(parseInt(e.target.value), x.Id, 'StokeTypeId')}
                                                    value={x.StokeTypeId}
                                                >
                                                    {stock_type.map((item) => {

                                                        return <option
                                                            key={item.Id}
                                                            value={item.Id}
                                                        >{item.Name}
                                                        </option>
                                                    })}
                                                </select>
                                            </div>}
                                    </td>
                                </tr>
                                : null
                        ) : null}
                    </tbody>
                </table>
            </div>

            {inventory.length >= 1 ?
                <div className='save-btn'>
                    <button className='btn' onClick={SaveProd}>
                        שמור
                    </button>
                    <button className='btn' onClick={SaveChanges}>
                        עדכן שינוי
                    </button>
                    <button className='btn' onClick={Cancel}>
                        בטל
                    </button>
                </div>
                : null

            }
        </Fragment>

    )
}

export default InventoryTable
