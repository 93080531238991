import React, { useState, useEffect } from "react";
import { polygon, polygonAdd, polygonPointAdd, deletePoint, polygonStation, deletePolygon } from '../../../server/Polygon';
import ShowMap from './ShowMap'
import AddPoint from './AddPoint'
import { useSelector } from "react-redux";
import StationDelivery from "./StationDelivery";
import showAlert from '../../../server/ShowAlert';

const StationPlace = () => {
    const [markers, setMarks] = useState([])
    const [station, setStation] = useState(null)
    const [newMark, setNewMarks] = useState(null)

    const stationId = useSelector(state => state.user.stationId)
    useEffect(() => {
        polygon(stationId)
            .then(x => {
                setStation(x.data.station)
                setMarks(x.data.polygon)
            })
            .catch(() => console.log("error"));
    }, [])

    const EditPoint = (val) => {
        val.stationId = stationId;
        polygonAdd(val).then(x => {
            const copyMarks = [...markers];
            if (val.Id > 0) {
                const index = copyMarks.findIndex(x => x.Id === val.Id);
                copyMarks[index] = val;
                showAlert({ msgTitle: 'הכתובת עודכנה', msgText: '', msgType: 'success' })
            }
            else {
                x.data.polygon_point = []
                copyMarks.push(x.data);
                showAlert({ msgTitle: 'הכתובת נשמרה', msgText: '', msgType: 'success' })
            }
            setMarks(copyMarks);
        })
    }
    const EditStation = (val) => {
        polygonStation(val).then(x => {
            showAlert({ msgTitle: 'הכתובת עודכנה', msgText: '', msgType: 'success' })
            setStation(x.data);
        })
    }
    const addMarkPoint = ({ target: { value } }) => {
        if (value > 0) {
            const data = {
                PolygonId: value,
                ...newMark
            }
            polygonPointAdd(data).then(x => {
                const copyMarks = JSON.parse(JSON.stringify(markers));
                copyMarks.find(y => y.Id === x.data.PolygonId).polygon_point.push(x.data);
                setMarks(copyMarks);
                setNewMarks(null)
                showAlert({ msgTitle: 'נקודה חדשה התווספה', msgText: '', msgType: 'success' })

            })
        }
    }

    const deleteMark = (data) => {
        if (data.Id === -1) {
            setNewMarks(null);
        }
        else {
            deletePoint(data).then(() => {
                const copyMarks = JSON.parse(JSON.stringify(markers));
                const index = copyMarks.findIndex(y => y.Id === data.PolygonId);
                copyMarks[index].polygon_point = copyMarks[index].polygon_point.filter(x => x.Id !== data.Id);
                setMarks(copyMarks);
                showAlert({ msgTitle: 'נקודה נמחקה', msgText: '', msgType: 'success' })
            })

        }
    }
    const deletePolygons = (data) => {
        deletePolygon(data)
            .then(() => {
                const copyMarks = markers.filter(x => x.Id !== data.Id);
                setMarks(copyMarks);
                showAlert({ msgTitle: 'הכתובת נמחקה', msgText: '', msgType: 'success' })
            })
            .catch(() => console.log("error"))
    }
    const markersSow = [];

    markers.forEach(x => x.polygon_point.forEach(mark => {
        markersSow.push({
            PointName: x.PointName,
            PointId: x.Id,
            ...mark
        })
    }))
    if (newMark) {
        markersSow.push({ PointName: "נקודה חדשה", ...newMark })
    }
    return (
        <div className="station-place">
            <h1 className='title-role'>מחסן- פרטי משלוח</h1>
            <span className="station-place-map">
                {station ? <ShowMap markers={markersSow} setAddMarks={setNewMarks} deleteMarker={deleteMark} centerPlace={station} /> : null}
                <span className="all-point">
                    {newMark ?
                        <div>
                            <div>בחר מיקום</div>
                            <select onChange={addMarkPoint}>
                                <option value={-1}>בחר מיקום</option>
                                {markers.map(y => <option key={y.Id} value={y.Id}>{y.PointName}</option>)}
                            </select>
                        </div>

                        : null}
                    {markers.map(x => <AddPoint key={x.Id} point={x} savePoint={EditPoint} deletePoint={deletePolygons} />)}
                </span>
            </span>
            <AddPoint savePoint={EditPoint} />
            {station ? <StationDelivery station={station} savePoint={EditStation} /> : null}
        </div>
    )

}

export default StationPlace;