import React from 'react'

const SelectNumPay = ({ numPayments, setNumPayment, numPayment, disabled }) => {
    const arr = []

    if (numPayments > 1) {
        for (let i = 1; i <= numPayments; i++) {

            arr.push(i);
        }
    }
    return (
        arr.length ? <div className='num-payment'>
            <p>מספר תשלומים</p>
            <select value={numPayment} onChange={(e) => setNumPayment(e.target.value)} disabled={disabled}>
                {arr.map(x => <option value={x} key={x}>{x}</option>)}
            </select>
        </div> : null

    )
}

export default SelectNumPay
