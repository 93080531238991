import axios from 'axios';
import { url } from '../../axiosInstance';




export const UploadImage = (data) => {
    return axios.post(`${url}UploedImage`, data,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const getImgAdmin = () => {
    return axios.get(`${url}ImageForAdmin`,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
export const UploadImageData = (data) => {
    return axios.post(`${url}UploedImageForAdmin`, data,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}