import axios from 'axios';
import { url } from '../../axiosInstance';

export const orderPost = ( data) => {
    return axios.post(`${url}order`, data, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}


export const orderGet = ( data) => {
    return axios.get(`${url}Orders?from=${data.from}&to=${data.to}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const orderPerCustomer = ( customerId) => {
    return axios.get(`${url}order?customerId=${customerId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}