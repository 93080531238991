import React, { useEffect, useState } from 'react'
import showAlert from '../../../server/ShowAlert';
import { Save } from '../../../server/Advertising';
import MyCropper from "../../tools/MyCropper";
import { globalFileServer } from '../../../../axiosInstance'
import SelectCategory from './SelectCategory';

const Advertising = ({ advertisingData, saveChange, saveImg }) => {
    const [data, setData] = useState({})
    const [sowCategory, setSowCategory] = useState(false);
    const [sowCategoryId, setSowCategoryId] = useState(false);

    useEffect(() => {
        setData(advertisingData || {})
    }, [advertisingData])

    const selectCategoryUrl = ({ url, selectCategory }) => {
        setData({ ...data, Url: `category/${url}`, CategoryId: selectCategory })
        setSowCategory(null)
    }

    const selectCategoryId = ({ selectCategory, name }) => {
        setData({ ...data, CategoryId: selectCategory, CategoryName: name })
        setSowCategoryId(false)
    }

    const send = () => {
        if (!data.Url && !data.CategoryId) {
            showAlert({ msgTitle: "חסר מידע" })
        }
        else {
            Save(data).then(x => saveChange(x.data))
        }
    }

    return (
        <div className='add-advertising'>
            <div className='checkbox'>
                <p>בלחיצה על התמונה להפנות ל:</p>
                <label>מבצעים</label>
                <input type="checkBox" checked={data.Url === "/sales"} onChange={() => setData({ ...data, Url: data.Url === "/sales" ? '' : "/sales" })} />
                <label>מחלקה</label>
                <input type="checkBox" checked={data.Url?.includes("category")} readOnly={true} />
            </div>
            <button className='btn' onClick={() => setSowCategory(!sowCategory)}> בחר מחלקה {data.Url?.includes("category") ? "אחרת" : ""}</button>
            {sowCategory ? <SelectCategory setSelect={selectCategoryUrl} /> : null}
            {data.Url}

            <button className='btn' onClick={() => setSowCategoryId(!sowCategoryId)}> בחר מחלקה איפה להציג</button>
            {data.CategoryId} {data.CategoryId && data.CategoryName ? "|" : null} {data.CategoryName}
            {sowCategoryId ? <SelectCategory setSelect={selectCategoryId} /> : null}
            <div className='date-and-checkbox'>
                <input type="date" value={data.ToDate} onChange={({ target }) => setData({ ...data, ToDate: target.value })} />
                <label>פעיל</label>
                <input type="checkBox" checked={data.Active} onChange={() => setData({ ...data, Active: !data.Active })} />
            </div>

            {data.Id ? <MyCropper
                itemId={data.Id}
                folder="Advertising"
                uploadImg={({ FileName }) => saveImg({ Id: data.Id, PathUrl: FileName })}
                NameId="Id"
                Table="advertising"
                beforeFileName={`id_${data.Id}_`}
            /> : null}

            {data.PathUrl ? <img
                className="main-img"
                src={globalFileServer + "Advertising/" + data.PathUrl}
            /> : null}
            <button className='btn' onClick={send}>{!data.Id ? " הוסף " : "עדכן"}</button>

        </div>
    )
}

export default Advertising
