import React, { useEffect, useState } from "react";
import { globalFileIcon } from "../../../axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { clearSearch, search } from "../../server/Product";
import { useDispatch, useSelector } from "react-redux";

const Search = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchString = useSelector(
    (state) => state.displayProduct.searchString
  );
  const [searchStringHear, SetSearchString] = useState(searchString);

  useEffect(() => {
    SetSearchString(searchString);
    if (searchString.length > 2) {
      if (pathname !== "/search") {
        navigate("/search");
      }
    } else {
      if (pathname === "/search") {
        navigate(-1);
      }
    }
  }, [searchString]);

  const handleVoiceSearch = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("דפדפן זה אינו תומך בזיהוי דיבור");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "he-IL";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const voiceInput = event.results[0][0].transcript;
      SetSearchString(voiceInput);
      dispatch(search(voiceInput));
      if (pathname !== "/search") {
        navigate("/search");
      }
    };

    recognition.onerror = (error) => {
      console.error("שגיאה בזיהוי הדיבור:", error);
    };

    recognition.start();
  };

  return (
    <div className="search-cont">
      <img
        loading="lazy"
        className="placeholder"
        src={globalFileIcon + "search-voice.svg"}
        alt="search-voice"
        onClick={handleVoiceSearch}
      />
      <input
        placeholder="חיפוש מוצר..."
        className="for-search"
        type="text"
        onChange={(e) => SetSearchString(e.target.value)}
        onBlur={(e) => dispatch(search(e.target.value))}
        value={searchStringHear}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(search(e.target.value));
          }
        }}
      />
      {searchStringHear === "" ? (
        <img
          loading="lazy"
          className="placeholder"
          src={globalFileIcon + "search.svg"}
          alt="search"
        />
      ) : (
        <img
          loading="lazy"
          onClick={() => dispatch(clearSearch())}
          className="placeholder"
          src={globalFileIcon + "crose-close.svg"}
          alt="cross"
        />
      )}
    </div>
  );
};

export default Search;
