import React from "react";
import { globalFileServer } from '../../../axiosInstance';
import { useSelector  } from 'react-redux';
import ProductAddToCart from "../routs/productPage/ProductAddToCart";
import ProductInfo from "../productInfo/ProductInfo";

const MiniCart = () => {

	const { productsInCart, productSale, openCart, user, role, deliveryPrice } = useSelector(state => ({
		productsInCart: state.productsInCart.productsInCart,
		productSale: state.productsInCart.productSale,
		openCart: state.openCart.openCart,
		user: state.user.user,
		role: state.user.role,
		deliveryPrice: state.productsInCart.deliveryPrice,
	}) );
	let saleItem;
	return (
		<div className={openCart && !role && user ? "header-cart opened" : "header-cart closed"}>
			<div className="header-cart-wrap">

				<div className="products">
					<table>
						<tbody>
							{productsInCart.length ? productsInCart.map(element => {
								saleItem = productSale.find(item => item.CollectSiteProductId == element.Id)
								return (
									<tr className="item-mini-cart" key={element.Id}>
										<td className="img-item-td">
											<div className="img-item" style={element.PathUrl ? { backgroundImage: 'url(' + globalFileServer + 'products/' + element.PathUrl.split(",")[0] + ')' } : null} />
										</td>
										<td className="prod-info-and-name">
											<ProductInfo selectedProd={element} />
											{saleItem?.Text ? <p className="sale">במבצע! </p> : null}
											<div className="comment-warning">{element.CommentWarning}</div>
										</td>
										<td className="quantity">
											<ProductAddToCart element={element} haveInfo={false} />
										</td>
										<td className="price-details">
											<p className="price">{(element.Price * element.Quantity).toFixed(2)} <span>₪</span></p>
											{saleItem?.Price ? <p className="sale">
												{(saleItem.Price * saleItem.QuantiyUse).toFixed(2)} <span>- ₪</span>
											</p> : ""}

										</td>
									</tr>
								)
							})
								: null}
							<tr className="delivery-price">
								<td><p >מחיר משלוח: </p></td>
								<td><p className="price">{deliveryPrice} <span>₪</span></p></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</div>
	)
}

export default MiniCart;