import React, { useState } from 'react'
import Autocomplete from 'react-google-autocomplete';
import { useForm } from 'react-hook-form';
import Input from "../../containers/InputForm";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import showAlert from '../../../server/ShowAlert';



const schema = yup.object({
    MinFreeDeleviry: yup.string().required("שדה חובה"),
    MinPrice: yup.number().positive().required(),
    Location: yup.string().required()
}).required();




const StationDelivery = ({ savePoint, station }) => {
    let latlng = { lat: station.lat, lng: station.lng };
    const { register, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues: { ...station },
        resolver: yupResolver(schema),
    });
    const setLocation = (place) => {
        
        if (!place.geometry) {
            showAlert({ msgTitle: 'אנא הזינו את שם הרחוב בלבד ובחרו מהשדות המוצעות לכם', msgTimer: '3000', msgType: 'info' })
            return;
        }
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng()
        latlng = { lat, lng };
        setValue("Location",place.formatted_address)
    }

    const sendData=(data)=>{
        const val={...data,...latlng};
        savePoint(val)
    }
    return (
        <form className='station-delivery' onSubmit={handleSubmit(sendData)}>
            <h3>פרטי תחנה</h3>
            <div className='flex-container'>
                <Input
                    errors={errors}
                    label="מחיר מינימום לקניה"
                    name="MinPrice"
                    type="text"
                    register={register} />
                <Input
                    errors={errors}
                    label="מחיר למשלוח מינימום"
                    name="MinFreeDeleviry"
                    type="text"
                    register={register}
                />
                <Autocomplete
                    options={{
                        componentRestrictions: { country: 'ISR' },
                        types: ["route"],
                    }
                    }
                    onPlaceSelected={setLocation}
                />
                <Input
                    errors={errors}
                    label="כתובת"
                    name="Location"
                    type="text"
                    register={register}
                />
                <button className='btn' type='submit'>עדכן</button>
            </div>
        </form>
    )
}

export default StationDelivery
