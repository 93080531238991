import React from 'react'
import { globalFileIcon } from '../../../axiosInstance';

const AboutHamazonSite = () => {
  return (
    <div className='about-page'>
      <span className='about-part'>
        <span className='all-text'>
          <h1 className='about-header'>הדור הבא <br />של הקמעונאות</h1>
          <p className='about-text'>׳המזון׳ הוקם מתוך מטרה וחזון ליצור מהפכה בשוק הזמנת המזון אונליין בישראל
            <br />ולאפשר לכל אדם להזמין ולקבל בדיוק את מה שצריך מיד כשצריך</p>
          <a className='arrow-down' href="#innovation">
            <img loading="lazy" src={globalFileIcon + 'arrowDown.svg'} alt="arrowDown" />מהפכת החדשנות </a>
        </span>
        <img loading="lazy" src={globalFileIcon + 'about1.png'} alt="img" />
      </span>

      <span id="innovation" className='about-part-tow'>
        <img loading="lazy" className='img-about' src={globalFileIcon + 'about2.png'} alt="img" />
        <span className='all-text'>
          <h1 className='about-header'>מהפכת<br /> החדשנות</h1>
          <p className='about-text'>  רובוטים חדשניים אשר פותחו במיוחד עבור ׳המזון׳ מפעילים את המרלוגים השכונתיים ומנהלים מלאי מדויק המשתקף מיידית באתר. כך, לא ניתן להזמין מוצר שאינו במלאי וכל מוצר שיוזמן - יגיע.</p>
          <p className='about-text bold'>חוסכים את טעויות האנוש ומקבלים בדיוק מה שמזמינים.</p>
          <a className='arrow-down' href="#shipping"><img loading="lazy" src={globalFileIcon + 'arrowDown.svg'} alt="arrowDown" />מהפכת השילוח </a>
        </span>
      </span>

      <span id="shipping" className='about-part aligned'>
        <span className='all-text'>
          <h1 className='about-header'>מהפכת<br /> השילוח</h1>
          <p className='about-text'> כלי ממונע יחודי יוצר על פי דרישות ׳המזון׳ עם יכולת העמסה של עד 3 כתובות בלבד, לשילוח זריז ומיידי.</p>
          <p className='about-text bold'>הרובוטים מוזנקים לאיסוף מוצרי ההזמנה והשליח מיד יוצא, להספקה מהירה בתוך 3 שעות בלבד.</p>
          <a className='arrow-down' href="#price"><img loading="lazy" src={globalFileIcon + 'arrowDown.svg'} alt="arrowDown" />מהפכת המחיר </a>
        </span>
        <img loading="lazy" src={globalFileIcon + 'about3.png'} alt="img" />
      </span>

      <span id='price' className='about-part aligned price'>
        <img loading="lazy" src={globalFileIcon + 'about4.png'} alt="img" />
        <span className='all-text'>
          <h1 className='about-header'>מהפכת<br /> המחיר</h1>
          <p className='about-text'> ניהול סניפים ומרלוג מרכזי סופר-חכם במלאי מדויק ברמה שכונתית ובמינימום כח אדם, מאפשרים צמצום בעלויות העובדים ובשטח הנדל״ני.</p>
          <p className='about-text bold'>התוצאה: מחיר סופר משתלם.</p>
          <a className='arrow-down' href="#connect"><img loading="lazy" src={globalFileIcon + 'arrowDown.svg'} alt="arrowDown" />יש לך רעיון?</a>
        </span>
      </span>

      <span id='connect' className='about-part aligned'>
        <span className='all-text'>
          <h1 className='about-header'>תמיד נשמח<br /> להשתפר</h1>
          <p className='about-text'> כחלק מהחזון ליצור את חווית השירות הטובה ביותר, באתר ׳המזון׳ כל המידע הרלוונטי מוצג בצורה בהירה וברורה</p>
          <p className='about-text bold'>נתקלת בתקלה? יש לך רעיון לשיפור?</p>
          <p className='about-text color'>שלחו אלינו מייל: a@hamazon.co.il</p>
          <a className='arrow-down' href="/"><img loading="lazy" src={globalFileIcon + 'arrowSide.svg'} alt="arrowSide" />להתחלת רכישה</a>
        </span>
        <img loading="lazy" src={globalFileIcon + 'about5.png'} alt="img" />
      </span>
    </div>
  )
}

export default AboutHamazonSite
