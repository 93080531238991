import React, { Fragment, useState } from 'react'
import { setUserEntry, LogOut } from "../../server/Customer";
import {  globalFileIcon } from '../../../axiosInstance';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import Accessibility from '../Accessibility'
import CustomerService from '../customerService/CustomerService';
import PrivateAreaList from '../privateArea/PrivateAreaList';
import { setToggleCart } from "../../server/CartsOpen";
import { FiArrowLeft } from 'react-icons/fi';

const HeaderConnection = ({ user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [openPrivateAreaList, setOpenPrivateAreaList] = useState(false);
    
    const handleNavigation = () => {
        dispatch(setToggleCart(false));
        navigate('/about');
    };

    const exit = () => {
        navigate('/');
        dispatch(LogOut())
    }
    return (
        <div className='header-connection'>
            <NavLink to="/" className='site-icon'><img  loading="lazy" src={globalFileIcon + 'site-icon.svg'} alt="site" /></NavLink>
            <div className='header-data'>

                <li className="data" onClick={handleNavigation}>
                    <img loading="lazy" src={globalFileIcon + 'about.svg'} alt="about" />
                   <span className="about">אודות</span>
                </li>
                <li className={openMenu? "data open-menu":"data" } onClick={() => setOpenMenu(true)} >
                    <img  loading="lazy" src={globalFileIcon + 'customer-service.svg'} alt="customer-service" />
                    <a className='customer-service'>שירות לקוחות</a>
                </li>
                {openMenu ? <div className='customer-service-menu'><CustomerService setOpenMenu={setOpenMenu} /></div> : null}
                <Accessibility />
            </div>
            <div className="login-or-sign-in">
                {!user ?
                    <div>
                        <button className="login" onClick={() => dispatch(setUserEntry([true, "login"]))}>
                            כניסה
                        </button>
                        <button className="sign-in" onClick={() => dispatch(setUserEntry([true, "signIn"]))}>
                            הרשמה
                        </button>
                    </div>
                    :
                    <div>
                        {!user.UserTypeId  ? <Fragment>
                            <button onClick={() => setOpenPrivateAreaList(true)} className="btn-to-private-area">
                                <span> לאזור האישי של { user.Name } {user.LastName}</span>
                            </button>
                            {openPrivateAreaList ? <div className='private-area-list-menu'><PrivateAreaList setOpenPrivateAreaList={setOpenPrivateAreaList} /></div> : null}
                        </Fragment> : null}

                        <button onClick={exit} className="exit">
                            <span>יציאה</span>
                        </button>
                        <button onClick={()=>navigate(-1)} className="exit">
                            <FiArrowLeft />
                        </button>
                    </div>

                }

            </div>
        </div>
    )
}

export default HeaderConnection
