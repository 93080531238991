import React from 'react'
import { globalFileIcon } from '../../../axiosInstance'

const SubFooter = () => {
  return (
    <div className='sub-footer'>
      				<a  className="click-and" >
                      <img  loading="lazy" src={globalFileIcon + 'hand-click.svg'} alt="hand-click" />
                        <h3>קליק וקיבלת</h3>
                        <h5>4 שעות אצלך בבית</h5>
                    </a>
                    <a  className="click-and" >
                      <img  loading="lazy" src={globalFileIcon + 'chose-click.svg'} alt="chose-click" />
                        <h3>קליק ובחרת</h3>
                        <h5>הכשרים מהודרים לכל המוצרים</h5>
                    </a>
                    <a  className="click-and" >
                      <img  loading="lazy" src={globalFileIcon + 'earn-click.svg'} alt="earn-click" />
                        <h3>קליק והרווחת</h3>
                        <h5>עשרות מבצעים שווים!</h5>
                    </a>

    </div>
  )
}

export default SubFooter
