import axios from 'axios';
import { url } from '../../axiosInstance';

export const getListOfNewImage= () => {
    return axios.get(`${url}Product/newImg`,{
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}
