import React from 'react';
import './deleteCategoryDialog.scss'

const DeleteCategoryDialog = ({ handleDelete, handleClose }) => {
  return (
    <div className="delete-category-dialog-overlay">
        <div className="delete-category-dialog-box">
            <h2>האם אתה בטוח שברצונך למחוק את המחלקה?</h2>
            <p>יש מוצרים שמשויכים אליה.</p>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleDelete();
            }}>
                <button type="submit">כן</button>
                <br/>
                <button type="button" onClick={handleClose}>לא</button>
            </form>
        </div>
    </div>
  );
};

export default DeleteCategoryDialog;
