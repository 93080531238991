import React, { useEffect, useState } from 'react';
import { getSpecialDays, specialDaysPost } from '../../../server/DeliveryTimeEdit'
import Input from '../../containers/Input';
import showAlert from '../../../server/ShowAlert'
import TimeYearMonthDate from '../../containers/Date'
import { useDispatch } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';

const DeliveryTimeSpecialDay = ({ dayTypes }) => {
    const dispatch = useDispatch();
    const [specialDays, setSpecialDays] = useState([]);

    useEffect(() => {
        GetSpecialDays()
    }, [])

    const GetSpecialDays = () => {
        getSpecialDays()
            .then(x => {
                const listSpecialDate = x.data;
                const emptyDate = {
                    Id: -1,
                    Name: "",
                    Date: "",
                    DateTypeId: null,
                    date_type: {},
                }
                listSpecialDate.push(emptyDate)
                setSpecialDays(x.data)
            })
            .catch(() => console.log("error"));
    }

    const HandleSetName = (value, id, key) => {
        const specialDay = [...specialDays];
        const index = specialDay.findIndex(x => x.Id === id)
        const specialDayIndex = { ...specialDay[index] }
        specialDayIndex[key] = value;
        specialDay[index] = specialDayIndex;
        setSpecialDays(specialDay);

    }

    const saveChanges = (id) => {

        const changeDayType = specialDays.find(x => x.Id === id);
        if (changeDayType.Date === "" || changeDayType.Name === "") {
            showAlert({ msgTitle: ' אחד או יותר מהנתונים שגויים.', msgType: 'error' })
        }
        else if (!changeDayType.DateTypeId) {
            showAlert({ msgTitle: ' לא נבחר סוג יום מיוחד.', msgType: 'error' })
        }
        else {
            dispatch(setPreload(true));
            specialDaysPost(changeDayType)
                .then(x => {
                    showAlert({ msgTitle: 'השינויים נשמרו בהצלחה.', msgType: 'success' })
                })
                .catch(() => showAlert({ msgTitle: 'הייתה בעיה בשמירת הנתונים.', msgType: 'error' }))
                .finally(() => dispatch(setPreload(false)));

        }
    }

    return (
        <div className="delivery-time-special-day">
            <div className='table-data'>
                <table>
                    <tbody>
                        <tr>
                            <th>שם יום </th>
                            <th>תאריך</th>
                            <th>סוג יום </th>
                            <th></th>
                        </tr>
                        {specialDays.length ? specialDays.map(x => <tr key={x.Id}>
                            <td>
                                <Input
                                    type="text"
                                    Value={x.Name}
                                    className='input'
                                    InputChange={(value) => HandleSetName(value, x.Id, "Name")}
                                />
                            </td>
                            <td>
                                <div className='date'>
                                    <input
                                        type="date"
                                        value={TimeYearMonthDate(x.Date)}
                                        onChange={(e) => HandleSetName(e.target.value, x.Id, "Date")}
                                    />
                                    <div className='date-day'>
                                        {x.Date ? new Date(x.Date).toLocaleDateString('he-IL', { weekday: 'long' }) : null}
                                    </div>
                                </div>


                            </td>
                            <td>
                                <select
                                    className='select-day-type'
                                    onChange={(e) => HandleSetName(e.target.value, x.Id, "DateTypeId")}
                                    key={x.Id}
                                    value={x.DateTypeId}
                                >
                                    <option value="" disabled selected>בחר</option>
                                    {dayTypes.map((item) => <option
                                        key={item.Id}
                                        value={item.Id}
                                    >{item.Name}({item.Start}-{item.End})
                                    </option>
                                    )}
                                </select>
                            </td>
                            <td>
                                <button className='btn' onClick={() => saveChanges(x.Id)}>
                                    שמור
                                </button>
                            </td>

                        </tr>
                        ) : null}

                    </tbody>
                </table >
            </div>
        </div>
    )
}

export default DeliveryTimeSpecialDay