import React from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { CollectSiteProductSetProduct } from '../../../server/CollectSite';
import { setUserEntry } from '../../../server/Customer'


const ProductAddToCart = ({ element, haveInfo }) => {
  const dispatch = useDispatch();
  const { user, role, productsInCart } = useSelector(state => ({
    role: state.user.role,
    user: state.user.user,
    productsInCart: state.productsInCart.productsInCart,

  }) );

  const inCart = productsInCart.find(item => item.ProductMkt === element.Mkt || item.ProductMkt === element.ProductMkt);

  const CollectSiteProductSetProducts = (element, quantity) => {
    if (!user) {
      dispatch(setUserEntry([true, "signIn"]))
    }
    else {
      dispatch(CollectSiteProductSetProduct(element, quantity))
    }
  }

  const WeightJump = element.WeightJump > 0 ? element.WeightJump : 1;

  return (
    <div className={inCart ? "add-to-cart in-cart" : "add-to-cart not-in-cart"}>

      {/* <div className="product-page barcode-pop"> */}
      {!role && (element.QuantityCanOrder >= WeightJump || (inCart && inCart.Quantity > 0)) ?
        // <div className="a" onClick={!inCart ? () => CollectSiteProductSetProducts(element, 1) : null}>
        <div className="wrap flex-container" onClick={!inCart ? () => CollectSiteProductSetProducts(element, 1) : null}>
          {inCart && inCart.Quantity > 0 ?
            <>
              <div className="add-btn" onClick={inCart && (!haveInfo || element.QuantityCanOrder >= WeightJump) ? () => CollectSiteProductSetProducts(element, 1) : null}>
                {/* <img  loading="lazy" src={globalFileIcon + 'plus-clean.svg'} alt="add" /> */}
                <p>+</p>
              </div>
              <div className="col-lg-7">
                <input readOnly
                  type="text"
                  value={inCart.Quantity}
                  disabled={true}
                />
              </div>
              <div className="minus-btn" onClick={inCart ? () => CollectSiteProductSetProducts(element, -1) : null}>
                {/* <img  loading="lazy" src={globalFileIcon + 'cart_minus.svg'} alt="minus" /> */}
                <p>-</p>
              </div>
            </> :
            <p onClick={inCart && (!haveInfo || element.QuantityCanOrder >= WeightJump) ? () => CollectSiteProductSetProducts(element, 1) : null}>הוספה</p>
          }
        </div>
        : null}
    </div>
  )
}



export default ProductAddToCart;