import React, { useEffect, useState } from 'react';
import { getDateDayOfWeek, dateDayOfWeekPost } from '../../../server/DeliveryTimeEdit'
import showAlert from '../../../server/ShowAlert'
import { useDispatch } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';

const DeliveryTimeDayOfWeek = ({ dayTypes }) => {
    const dispatch = useDispatch();
    const [dayOfWeek, setDayOfWeek] = useState([]);

    useEffect(() => {
        GetDateDayOfWeek()
    }, [])
    const HandleDayTypeId = (value, checkedItem) => {
        const day = [...dayOfWeek];
        const index = dayTypes.findIndex(x => x.Id === checkedItem)
        day[index].DateTypeId = value;
        setDayOfWeek(day)
    }

    const GetDateDayOfWeek = () => {
        getDateDayOfWeek()
            .then(x => {
                setDayOfWeek(x.data)
            })
            .catch(() => console.log("error"));
    }

    const saveChanges = (id) => {
        dispatch(setPreload(true));
        const changeDayType = dayOfWeek.find(x => x.Id === id);
        dateDayOfWeekPost(changeDayType)
            .then(x => {
                if (x.data) {
                    showAlert({ msgTitle: 'השינויים נשמרו בהצלחה.', msgType: 'success' })
                }
            })
            .catch(() => showAlert({ msgTitle: ' הייתה בעיה בשמירת הנתונים.', msgType: 'error' }))
            .finally(() => dispatch(setPreload(false)));
    }

    return (
        <div className="delivery-time-day-of-week">
            <div className='table-data'>
                <table>
                    <tbody>
                        <tr>
                            <th>שם יום </th>
                            <th>סוג יום </th>
                            <th></th>
                        </tr>
                        {dayOfWeek.length ? dayOfWeek.map(x => <tr key={x.Id}>
                            <td key={x.Id}>
                                {x.Name}
                            </td>
                            <td>
                                <select
                                    className='select-day-type'
                                    onChange={(e) => HandleDayTypeId(e.target.value, x.Id)}
                                    value={x.DateTypeId}                                >
                                    {dayTypes.map(item => <option
                                        key={item.Id}
                                        value={item.Id}
                                    >
                                        {item.Name}({item.Start}-{item.End})
                                    </option>
                                    )}
                                </select>
                            </td>
                            <td>
                                <button className='btn' onClick={() => saveChanges(x.Id)}>
                                    שמור
                                </button>
                            </td>

                        </tr>
                        ) : null}
                    </tbody>
                </table >
            </div>
        </div>
    )
}

export default DeliveryTimeDayOfWeek