import React, { Fragment } from "react";
import SearchProduct from "../SearchProduct";
import { globalFileServer } from "../../../../axiosInstance";

const ProductEditPerloed = ({
  products,
  onSelectProduct,
  arrShow,
  removeLinkage,
  label,
}) => {
  return (
    <Fragment>
      {/* // <div className="linkage-main-cont"> */}
      <label>{label}</label>
      <SearchProduct products={products} onSelectProduct={onSelectProduct} />
      {/* // <div className="linkage-items-col"> */}
      {arrShow.length
        ? arrShow.map((alter, ind) => {
            return (
              <div className="linkage-items" key={ind}>
                <p>{alter.ProductName}</p>
                <div
                  onClick={() => removeLinkage(alter.ProductMkt)}
                  className="img"
                >
                  <img
                    loading="lazy"
                    src={globalFileServer + "iconcss/close-menu.svg"}
                    alt=""
                  />
                </div>
              </div>
            );
          })
        : null}
      {/* // </div>
        // </div> */}
    </Fragment>
  );
};

export default ProductEditPerloed;
