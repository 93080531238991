import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action'
import { setPreload } from '../server/CartsOpen';

export const DeliveryHoursGet = (StationId) => {
    return axios.get(`${url}DeliveryHours/Get?StationId=${StationId}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

export const DeliveryHoursSetTime = (data) => {
    return dispatch => {
        dispatch(setPreload(true));
        axios.post(`${url}DeliveryHours/SetTime`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then(x => {
                if (x.data) {
                    dispatch({ type: actionType.SET_ORDER_TIME, payload: data.OrderTime })
                }
                else {
                    dispatch({ type: actionType.SET_ORDER_TIME, payload: null })
                }
            })
            .catch(() => console.log("error"))
            .finally(() => dispatch(setPreload(false)));
    }
}



