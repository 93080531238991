import React from "react";
import { globalFileServer } from "../../../../axiosInstance";
import ProductInfo from "../../productInfo/ProductInfo";
import { useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";


const Detailes = ({ element, inCart, productPopUp, comparePricesPopUp}) => {

  const role = useSelector(state => state.user.role);

  return (
    <>
      <div className="item-card-details" onClick={productPopUp}>
        <div className={inCart ? "card-img-in-card" : "card-img"}>
          <img
            className={"img"}
            loading="lazy"
            src={
              element.PathUrl
                ? globalFileServer +
                  ("products/" +
                    element.PathUrl.split(",")[0] +
                    "?width=150&height=150")
                : globalFileServer + "placeholder.jpg"
            }
            alt={element.ProductName}
          />
          {element.SaleText ? (
            <span
              className="sale-text"
              style={{
                backgroundImage: `url(${
                  globalFileServer + "iconcss//saleBg.svg"
                })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
                backgroundColor: "transparent",
              }}
              loading="lazy"
            >
              <p>{element.SaleText}</p>
            </span>
          ) : null}
        </div>
        <div className="desc">
          <span className="product-info">
            <ProductInfo selectedProd={element} />
            <div className="prod-info-quantity">
              {element.PackageQuantity ? (
                <h4>
                  <span>{element.PackageQuantity}</span>

                  {element.WeightQuantity ? (
                    <>
                      <span> יחי' * </span>
                      <span className="weight-quantity">
                        {element.WeightQuantity}
                      </span>
                      <span>{element.UnitMeasure}</span>
                    </>
                  ) : (
                    <span>{element.UnitMeasure}</span>
                  )}
                </h4>
              ) : null}
            </div>
          </span>
          <div className="kosher-and-price">
            <div className="price-main-cont">
              {element.Price && element.Price !== "0" ? (
                <span 
                className="price" 
                onClick={(e) => { e.stopPropagation(); comparePricesPopUp(); }} 
                data-tip data-for="registerTipComparePrice">
                  {parseFloat(element.Price).toFixed(2)}
                </span>
              ) : null}
              {role && <ReactTooltip id="registerTipComparePrice" place="top" effect="solid">
                לחץ כדי להשוות מחיר.
              </ReactTooltip>}
            </div>
            {element.Kashrut ? (
              <div className="prod-info-kosher">
                {element.KashrutId != "19" ? (
                  <>
                    <img
                      loading="lazy"
                      src={
                        globalFileServer +
                        "iconcss/" +
                        element.KashrutId +
                        ".svg"
                      }
                      alt="kashrut"
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Detailes;
