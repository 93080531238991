import * as actionType from './action'

const initialState = {
    stations: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_STATIONS:
            return {
                ...state,
                stations:action.payload,
            };
     
        default:
            return state
    }
}

export default reducer; 