import React,{useState} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './components/routs/home/Home';
import Contacts from './components/routs/contacts/Contacts';
import CategoryPage from './components/routs/categoryPage/CategoryPage';
import Reset from './components/routs/Reset';
import CategoryEdit from './components/routs/categoryEdit/CategoryEdit';
import ProductsEdit from './components/routs/productsEdit/ProductsEdit';
import Filters from './components/routs/Filters';
import ProductEdit from './components/routs/productDetailsEdit/ProductEdit';
import Cart from './components/routs/cart/Cart';
import History from './components/routs/history/History';
import UserHistory from './components/routs/userHistory/UserHistory';
import UserShoppingList from './components/routs/shoppingList/UserShoppingList';
import InventoryUpdate from './components/routs/inventoryUpdate/InventoryUpdate';
import ListProductNewImage from './components/routs/listProductNewImg/ListProductNewImage';
import SalesManagement from './components/routs/sales/SalesManagement';
import Users from './components/routs/users/Users';
import PayPopup from "../js/components/routs/shopCart/PayPopup";
import { useSelector } from "react-redux";
import DeliveryTimeEdit from "./components/routs/deliveryTimeEdit/DeliveryTimeEdit";
import UserShoppingListProduct from "./components/routs/shoppingList/UserShoppingListProduct";
import { useNavigate } from 'react-router-dom';
import SalePage from "./components/routs/sales/SalePage";
import StationPlace from "./components/routs/address/StationPlace";
import Station from "./components/routs/station/AddStation";
import Location from "./components/routs/station/Location";
import AdvertisingAll from "./components/routs/advertising/AdvertisingAll";
import AddImg from "./components/routs/adminImg/AddImg";
import AboutHamazonSite from "./components/about/AboutAmazonSite";
import PrivateArea from "./components/privateArea/PrivateArea";
import WorkersNeeded from "./components/footer/WorkersNeeded";
import ProductEditImg from "./components/routs/productDetailsEdit/ProductEditImg";
import ProductEditForm from "./components/routs/productDetailsEdit/ProductEditForm";
import ComparePricePopUp from "./components/routs/productPage/ComparePricePopUp";
import ProductLocationPopUp from "./components/routs/productPage/ProductLocationPopUp";
import Logistica from "./components/routs/productDetailsEdit/logistica";
import PersonalInformation from "./components/privateArea/PersonalInformation";
import AddressShipping from "./components/addressShipping/AddressShipping";
const BasicRouter = ({ canPay , setCurrentSubCategory, currentSubCategory}) => {
  const [selectedAddress, setSelectedAddres] = useState(null);

  const navigate = useNavigate();
  const { user, role, toggleMenu, openCart, toggleSidebar } = useSelector(state => ({
    user: state.user.user,
    role: state.user.role,
    toggleMenu: state.openCart.toggleMenu,
    openCart: state.openCart.openCart,
    toggleSidebar: state.openCart.toggleSidebar
  }));

  return (
    <main
      data-cart={openCart ? 'active' : null}
      data={toggleSidebar ? 'active' : null}
      className={toggleMenu ? 'active' : null}>
      <Routes>
        <Route path="/reset/:token" element={<Reset />} />
        {canPay ? <Route path="/payCustomer/:id" element={<PayPopup tranmode={false} closePayPopup={() => navigate('/')} />} /> : null}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutHamazonSite />} />
        <Route path="/contacts" element={<Contacts home={false} />} />
        <Route path="/category/:subId/:id" element={<CategoryPage setCurrentSubCategory={setCurrentSubCategory} currentSubCategory={currentSubCategory}/>} />
        <Route path="/category/:FatherId/:subId/:id" element={<CategoryPage setCurrentSubCategory={setCurrentSubCategory} currentSubCategory={currentSubCategory}/>} />
        {role ? <Route path="/products-edit/:id" element={<ProductsEdit />} /> : null}
        <Route path="/search" element={<CategoryPage />} />
        <Route path="/sales" element={<CategoryPage />} />
        <Route path="/category/:id" element={<CategoryPage setCurrentSubCategory={setCurrentSubCategory} currentSubCategory={currentSubCategory}/>} />
        {role ? <Route path="/station-info" element={<StationPlace />} /> : null}
        {role ? <Route path="/station" element={<Station />} /> : null}
        {role ? <Route path="/station-location" element={<Location />} /> : null}
        {role ? <Route path="/category-edit" element={<CategoryEdit />} /> : null}
        {role ? <Route path="/category-edit/:id" element={<CategoryEdit />} /> : null}
        {role ? <Route path="/category-edit/:subId/:id" element={<CategoryEdit />} /> : null}
        {role ? <Route path="/filters" element={<Filters />} /> : null}
        {role ? <Route path="/edit-product/:id" element={<ProductEdit />} >
          <Route path="information" element={<ProductEditForm />} />
          <Route path="logistic" element={<Logistica />} />
          <Route path="img" element={<ProductEditImg />} />
          <Route path="comper" element={<ComparePricePopUp />} />
          <Route path="inventory" element={<ProductLocationPopUp />} />
          <Route path="inventory-update" element={<InventoryUpdate />} />
          <Route path="" element={<Navigate to="information" />} />
        </Route> : null}
        {user && !role ? <Route path="/cart" element={<Cart />} /> : null}
        {role ? <Route path="/admin-history" element={<History />} /> : null}
        {role ? <Route path="/delivery-time-edit" element={<DeliveryTimeEdit />} /> : null}
        {role ? <Route path="/inventory-update" element={<InventoryUpdate />} /> : null}
        {role ? <Route path="/new-image" element={<ListProductNewImage />} /> : null}
        {role ? <Route path="/sales-management" element={<SalesManagement />} /> : null}
        {role ? <Route path="/sale-page" element={<SalePage />} /> : null}
        {user ? <Route path="/history" element={<UserHistory name={user.Name} />} /> : null}
        {user ? <Route path="/shopping-list" element={<UserShoppingList name={user.Name} />} /> : null}
        {user ? <Route path="/shopping-list-product/:id" element={<UserShoppingListProduct />} /> : null}
        {user ? <Route path="/private-area" element={<PersonalInformation parent="personal-info" />} />: null}
        {user ? <Route path="/private-area/address" element={<AddressShipping setSelectedAddres={setSelectedAddres} parent="private-area" />} /> : null}

        {role ? <Route path="/users" element={<Users />} /> : null}
        {role ? <Route path="/advertising" element={<AdvertisingAll />} /> : null}
        {role ? <Route path="/addImg" element={<AddImg />} /> : null}
        <Route path="/workers-needed" element={<WorkersNeeded />} />


      </Routes>
    </main>

  );
}


export default BasicRouter;
