import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form';
import { UpdateProduct } from '../../../server/Product'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Input, { Select } from "../../containers/InputForm";
import ProductEditPerloed from './productEditPerloed';
import { LinkedProducts, LinkedProductsRemove } from '../../../server/LinkedProducts'

const schema = yup.object({
    ProductName: yup.string().required('שם המוצר חובה'),
    Barcode: yup.string().required('חובה להכניס ברקוד').test("len", x => x.length >= 5 && x.length <= 15),
    WeightQuantity: yup.number('').positive()
}).required();



const ProductEditForm = () => {
    const [catsLevel2, setCatsLevel2] = useState([])
    const [catsLevel3, setCatsLevel3] = useState([])
    const [alterProds, setAlterProds] = useState([])
    const [parallelProds, setParallelProds] = useState([])

    const { filterList, categoriesState, product, products,
        parameters: { p_view_out_of_stock, p_unit_measure, p_disable, p_box_type }
    } = useSelector(state => ({
        filterList: state.displayProduct.filterList,
        categoriesState: state.category.categories,
        product: state.selectedProduct.product,
        products: state.selectedProduct.products,
        parameters: state.parameters
    }));


    const { register, formState: { errors }, reset, handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: { ...product },
        resolver: yupResolver(schema),
    });


    useEffect(() => {
        if (product) {
            reset(product)
            if (product?.Category[0]) {
                setValue('catLevel1', product.Category[0].Id)
                setCatsLevel2(categoriesState.filter(x => x.FatherId === product.Category[0].Id))
            }
            else {
                setValue('catLevel1', null)
                setCatsLevel2([])
            }
            if (product.Category[1]) {
                setValue('catLevel2', product.Category[1].Id)
                setCatsLevel3(categoriesState.filter(x => x.FatherId === product.Category[1].Id))
            }
            else {
                setValue('catLevel2', null)
                setCatsLevel3([])
            }
            if (product.Category[2]) {
                setValue('catLevel3', product.Category[2].Id)
            }
            else {
                setValue('catLevel3', null)
            }
        }
        else {
            reset()
        }
    }, [product])

    const updateProduct = (value, paramName) => {
        const val = {
            Mkt: product.Mkt,
            Title: paramName,
            value: value
        };
        UpdateProduct(val)
    }
    const selectProductLink = (currProd, chosenProd, action) => {
        const val = {
            ProductMkt: currProd,
            SubProductMkt: chosenProd,
            TypeLinked: action
        };
        LinkedProducts(val)
            .then(() => {
                const match = products.find(prods => prods.Mkt === chosenProd);
                switch (action) {
                    case "1":
                        const alterProdsEdit = [...alterProds];
                        alterProdsEdit.push(match);
                        setAlterProds(alterProdsEdit)
                        break;
                    case "2":
                        const parallelProdsEdit = [...parallelProds];
                        parallelProdsEdit.push(match);
                        setParallelProds(parallelProdsEdit)
                        break;

                    default: break;
                }
            })
            .catch(() => console.log("error"));
    }

    const removeLinkage = (ProductMkt, SubProductMkt, TypeLinked) => {
        const val = { TypeLinked, ProductMkt, SubProductMkt };
        LinkedProductsRemove(val)
            .then(() => {
                if (TypeLinked === "1") {
                    const alterProds = alterProds.filter((item) => { return item.ProductMkt !== ProductMkt });
                    setAlterProds(alterProds)
                } else if (TypeLinked === "2") {
                    const parallelProds = parallelProds.filter((item) => { return item.ProductMkt !== ProductMkt });
                    setParallelProds(parallelProds)
                }
            })
            .catch(() => console.log("error"));
    }


    const tryUpdate = ({ target: { id, value } }) => {
        handleSubmit()
        if (!errors[id]) {
            updateProduct(value, id);
        }
    }

    const tryUpdateCategory = ({ target: { id, value } }) => {
        const category = categoriesState.filter(x => x.FatherId == value);
        switch (id) {
            case 'catLevel1': setCatsLevel2(category); setCatsLevel3([]); break;
            case 'catLevel2': setCatsLevel3(category); break;
        }
        if (id === 'catLevel3' || id === 'catLevel2') {
            updateProduct(value, "categoryId")
        }
    }

    return (
        <form className="product-details-edit" >

            <span className='name-and-img'>
                <span className='name-and-desc'>
                    <Input name="ProductName" errors={errors} onBlur={tryUpdate}
                        register={register} type="textarea" label="שם המוצר" />
                    <Input name="Description" errors={errors} onBlur={tryUpdate}
                        register={register} type="textarea" label="תיאור קצר" />

                </span>
            </span>
            <Container>
                <Row>
                    <InputCol sm={4} name="Mkt" errors={errors}
                        register={register} type="text" label="מק״ט" />
                    <InputCol sm={4} name="Barcode" errors={errors} onBlur={tryUpdate}
                        register={register} label="ברקוד" type="text" />
                    <SelectCol sm={4} name="ViewOutOfStockId" errors={errors} onChange={tryUpdate}
                        register={register} options={p_view_out_of_stock} label="סימון חוסר במלאי" />

                </Row>
                <Row>
                    <InputCol sm={4} name="SubMkt" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="מקט יצרן" />
                    <SelectCol sm={4} name="catLevel1" errors={errors} register={register} onChange={tryUpdateCategory}
                        options={categoriesState.filter(element => !element.FatherId && !element.SubParentId)} label="מחלקה" />
                    <div className='sort-field' sm={4}>
                        <h2>בחר שדות למיין</h2>
                        {filterList.map(element => <SelectCol
                            onChange={tryUpdate}
                            key={element.Name}
                            label={element.Name}
                            options={element.Items}
                            name={element.ParameterName}
                            errors={errors}
                            register={register}

                        />)}
                    </div>
                </Row>
                <Row>
                    <InputCol sm={2} name="WeightQuantity" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="משקל" />
                    <SelectCol sm={2} name="UnitMeasureId" errors={errors} onChange={tryUpdate}
                        register={register} options={p_unit_measure} label="יח משקל" />
                    <SelectCol sm={4} name="catLevel2" errors={errors} onChange={tryUpdateCategory}
                        register={register} options={catsLevel2} label="קבוצה" />
                </Row>
                <Row>
                    <InputCol sm={2} name="WeightJump" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="קפיצת משקל" />
                    <InputCol sm={2} name="GrossWeightLoss" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="סטיית משקל ברוטו" />
                    <SelectCol sm={4} name="catLevel3" errors={errors} onChange={tryUpdateCategory}
                        register={register} options={catsLevel3} label="תת קבוצה" />
                </Row>
                <Row>
                    <InputCol sm={2} name="PackageQuantity" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="כמות למארז" />
                    
                    <Col sm={4}>
                        <ProductEditPerloed
                            label="מוצר חלופי"
                            arrShow={alterProds}
                            onSelectProduct={(value) => selectProductLink(product.Mkt, value, "1")}
                            products={products}
                            removeLinkage={(value) => removeLinkage(product.Mkt, value, "1")}
                        />
                    </Col>
                </Row>
                <Row>
                   
                    <Col sm={4}>
                        <ProductEditPerloed label="מוצר מקביל"
                            arrShow={parallelProds}
                            onSelectProduct={(value) => selectProductLink(product.Mkt, value, "2")}
                            products={products}
                            removeLinkage={(value) => removeLinkage(product.Mkt, value, "2")}
                        />
                    </Col>
                </Row>

                <Row>
                    <InputCol sm={2} name="Price" errors={errors} onBlur={tryUpdate}
                        register={register} type="text" label="מחיר" />
                    <SelectCol sm={2} name="DisableProductId" errors={errors} onChange={tryUpdate}
                        register={register} options={p_disable} label="פעיל" />

                </Row>
            </Container>
            <div className='check-box'>
                <div className='checkbox-style'>
                    <Input name="VatProduct" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="פטור מע״מ" /></div>
                <div className='checkbox-style'>
                    <Input name="SupervisedPrice" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="בפיקוח" /></div>
                <div className='checkbox-style'>
                    <Input name="Weighable" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="מוצר שקיל" /></div>
                <div className='checkbox-style'>
                    <Input name="DepositProduct" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="פריט פקדון" /></div>
                <div className='checkbox-style'>
                    <Input name="ShowExpired" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="ניהול תוקף" /></div>
                <div className='checkbox-style'>
                    <Input name="ShowExpired" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="הצג תוקף" /></div>
                <div className='checkbox-style'>
                    <Input name="ViewBrand" errors={errors} onBlur={tryUpdate}
                        register={register} type="checkBox" label="הצג מותג" /></div>
                
            </div>
        </form >
    )
}

const InputCol = ({ name, errors, label, type, register, sm, onBlur }) => {
    return (
        <Col sm={sm}>
            <Input name={name} errors={errors} onBlur={onBlur}
                register={register} label={label} type={type} />
        </Col>)
}
const SelectCol = ({ name, errors, label, register, sm, options, onChange }) => {
    return (
        <Col sm={sm}>
            <Select
                name={name} errors={errors} {...register(name)}
                options={options} label={label} onChange={onChange} />
        </Col>)
}

export default ProductEditForm;