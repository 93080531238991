import React, { useEffect, useState, Fragment } from 'react'
import { useForm } from 'react-hook-form';
import { Select } from "../../containers/InputForm";
import Input from "../../containers/Input";
import LocationCell from './LocationCell';
import { geCells, SaveRowInfo } from '../../../server/Station';
import AddPlace from './AddPlace';
import showAlert from '../../../server/ShowAlert';
import { useSelector } from 'react-redux';


const LocationRow = ({ row, getCellP, rowsId, selectStation }) => {
  const { register, formState: { errors }, handleSubmit } = useForm({ defaultValues: { ...row } });
  const [addNew, setAddNew] = useState("");
  const { robot, status_location, p_box_type } = useSelector(state => state.parameters)

  useEffect(() => {
    if (addNew === "cells") {
      getCell()
    }
  }, [addNew]);
  const getCell = () => {
    // if (!row.Cells) {
    geCells(row.Id)
      .then(x => getCellP(row.Id, x.data))
      .catch(x => { })
    // }

  }

  const submit = (data) => {
    SaveRowInfo(data)
      .then(x => {
        x ? showAlert({ msgTitle: 'נתוני הקומה נשמרו בהצלחה', msgText: '', msgType: 'success' }) :
          showAlert({ msgTitle: "היה בעיה בשמירת הנתונים", msgText: '' })
      })
      .catch(x => { })
  }

  return (
    <Fragment>
      <form key={row.Id} onSubmit={handleSubmit(submit)} className="location-row">
        <Input Title="שם קומה" Value={row.Name} Disabled={true} />
        <Select className="location-select" name="BoxTypeId" errors={errors}
          {...register("BoxTypeId")} options={p_box_type} label="סוג ארגז" />
        <Select className="location-select" name="Active" errors={errors}
          {...register("Active")} options={status_location} label="פעיל" />
        <Select className="location-select" name="RobotId" errors={errors}
          {...register("RobotId")} options={robot} label="רובוט" />
        <div className='btn-save-change'>
          <button type='button' onClick={getCell}> הצג ארגזים</button>
          <input type='submit' value="שמור שינוי" />
        </div>
        <AddPlace selectStation={selectStation} LocationRowId={row.Id} LocationColumnId={row.LocationColumnId} setAddNew={setAddNew} />

      </form>
      <div className="location-box">
        {rowsId === row.Id && row.Cells ? row.Cells.map(x => <LocationCell key={x.Id} cell={x} />) : null}
      </div>
    </Fragment>)

}

export default LocationRow
