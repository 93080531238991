import * as actionType from './action'

const initialState = {

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PARAMETER:
            return { ...state, [action.key]: action.data }
        default:
            return state
    }
}
export default reducer;