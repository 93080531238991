import React from 'react';
import DeliveryHoursCalc from './DeliveryHoursCalc';
import DeliveryTimeDayTypes from './DeliveryTimeDayTypes';

const DeliveryTimeEdit = () => {

    return (
        <div className="delivery-time-edit">
            <h1 className='title-role'>עריכת זמני משלוח</h1>
            <DeliveryHoursCalc/>
            <DeliveryTimeDayTypes/>
        </div>
    )
}

export default DeliveryTimeEdit 