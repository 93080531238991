import React, { createContext, useState } from 'react';

const GlobalContext = createContext({
    email: 's@hamazon.co.il',
    subject: 'פניה למערכת המזון מתוך האתר'
});

const GlobalContextProvider = ({ children }) => {
    const [email, setEmail] = useState('s@hamazon.co.il');
    const [subject, setSubject] = useState('פניה למערכת המזון מתוך האתר');

    return (
        <GlobalContext.Provider value={{ email, subject }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalContextProvider };