import axios from 'axios';
import { url } from '../../axiosInstance';

export const GetProductsToOrder = (id) => {
    return axios.get(`${url}ProductToOrder/GetproductsToOrder?OrderId=${id}`, {
        headers: {
            'Authorization': `bearer ${localStorage.token}`,
            "Access-Control-Allow-Origin": "*"
        },
    })
}

