import React, { Fragment, useState } from 'react';
import ProductDetailsPopUp from "./ProductDetailsPopUp";
import ManualLocationPopUp from "./ManualLocationPopUp";
import { getIfProductPlaceFailedEmpty, getEmptyPlace } from '../../../server/InventoryUpdate'
import showAlert from '../../../server/ShowAlert'
import { useDispatch, useSelector } from 'react-redux'
import { setPreload } from '../../../server/CartsOpen';



const InventoryButton = ({ inputMkt, inventory, setInventory, saveLocations }) => {
    const dispatch = useDispatch();
    const [productDetailsPopUp, setProductDetailsPopUp] = useState(false);
    const [manualPositionPopUp, setManualPositionPopUp] = useState(false);
    const stationId = useSelector(state => state.user.stationId)


    const CheckCellId = (key, item) => {
        item.clicked = true
        if (key === -1) {
            setManualPositionPopUp(true);
        }
        else {
            const inventories = [...inventory]
            const checkedItem = inventory.find(x => x.CellId === key)
            if (checkedItem) {
                inventories.find(e => e.CellId === key).checkedCellId = true
                setInventory(inventories);
            }
            else {
                item.checkedCellId = true
                AddPlace(item);
            }
        }
    }

    const AddPlace = (inventoryAdd) => {
        const inventories = [...inventory]
        inventoryAdd.StationId = stationId;
        inventoryAdd.StokeTypeId = inventoryAdd.StokeTypeId ? inventoryAdd.StokeTypeId : 1;
        //לבדוק שהמיקום הזה עדיין לא קיים
        const item = inventories.find(x => x.CellId === inventoryAdd.CellId)

        // const item = inventories.find(x => x.FullLocationName === inventory.FullLocationName)
        if (!item) {
            inventoryAdd.ProductMkt = inputMkt
            inventories.push(inventoryAdd)
            setInventory(inventories)
        }
        setManualPositionPopUp(false)

    }

    const AutomaticPosition = () => {
        dispatch(setPreload(true));
        const mkt = inputMkt ? inputMkt : null;
        getIfProductPlaceFailedEmpty(mkt)
            .then(x => {
                if (!x.data) {
                    //קריאה לפונקציה של קבלת מקום פנוי
                    GetEmptyPlace(mkt, stationId)
                } else {
                    //פתיחת חלון פרטי מוצר
                    setProductDetailsPopUp(true)
                }
            })
            .catch(() => showAlert())
            .finally(() => dispatch(setPreload(false)));

    }

    const GetEmptyPlace = (mkt) => {
        getEmptyPlace(mkt, stationId)
            .then(x => {
                //להוסיף מיקום
                if (x.data) {
                    AddPlace(x.data)
                }
                else {
                    showAlert({ msgTitle: "המיקום לא התווסף", msgType: "error" })
                }
            })
            .catch(() => showAlert());
    }
    return (
        <Fragment>
            {inventory.length ? <div>
                <button className='btn' onClick={() => setProductDetailsPopUp(true)}>
                    פרטי מוצר
                </button>
                <button className='btn' onClick={AutomaticPosition}>
                    מיקום אוטומטי
                </button>
                <button className='btn' onClick={() => setManualPositionPopUp(true)}>
                    מיקום ידני
                </button>
                {saveLocations.map(x =>
                    <button className='btn' key={x.CellId} onClick={() => CheckCellId(x.CellId, x)} disabled={x.clicked ? true : false}>{x.FullLocationName}</button>
                )}
            </div> : null}
            {productDetailsPopUp ?
                <ProductDetailsPopUp
                    CloseProductDetails={() => { getEmptyPlace(inputMkt, stationId); setProductDetailsPopUp(false) }}
                    productMkt={inputMkt} /> : null}
            {manualPositionPopUp ?
                <ManualLocationPopUp
                    AddPlace={AddPlace}
                    CloseManualLocation={() => setManualPositionPopUp(false)}
                    productMkt={inputMkt} /> : null}
        </Fragment>
    )
}

export default InventoryButton
